import React from "react";
import {Role} from "../../../features/weerwolven/data/Role";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css";
import {setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectNightData, selectPersistentNightData} from "../../../features/weerwolven/slices/GameSelectors";
import {WeerwolvenDeathResult} from "../../../features/weerwolven/utils/night-solver/Utils";

// @ts-ignore
export function DoesHeksHealScreenPart(props: { weerwolvenResult: WeerwolvenDeathResult}) {
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const persistentNightData = useAppSelector(selectPersistentNightData)


    return (<>
        {props.weerwolvenResult.reasonOfThwart === Role.UNKNOWN ? persistentNightData.heksCanUseHealingPotion ?
                <><Action text={<><p>Wijs deze persoon aan:</p>
                    <p>
                        <button className={styles.NaamWeergave}>{nightData.weerwolven.currentWeerwolvenTarget}</button>
                    </p>
                </>}/>
                    <p className={`${styles.Vraag}`}>Gebruikt de Heks het levensdrankje?<br/>
                        <button className={`${styles.JaNee} ${nightData.heks.doesHeksHeal ? styles.Selected : ""}`}
                                onClick={() => {
                                    nightData.heks.doesHeksHeal = true
                                    dispatch(setNightData(nightData))
                                }}>Ja</button>
                        <button className={`${styles.JaNee} ${!nightData.heks.doesHeksHeal ? styles.Selected : ""}`}
                                onClick={() => {
                                    nightData.heks.doesHeksHeal = false
                                    dispatch(setNightData(nightData))
                                }}>Nee</button>
                    </p></> :
                <>
                    <Action text={<><p>Wijs deze persoon aan:</p>
                        <p>
                            <button className={styles.NaamWeergave}>{nightData.weerwolven.currentWeerwolvenTarget}</button>
                        </p>
                    </>}/>
                    <p>Het levensdrankje is al gebruikt.<br/><br/></p>
                </> :
            <Action text={<><p>Maak met gebaren duidelijk dat er vannacht niemand is gedood.</p></>}/>}
    </>)
}