import React, {JSX} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectDiefRoles, selectRoles} from "../../features/weerwolven/slices/PreGameSelectors";
import {SelectableRole} from "../common/SelectableRole";
import {displayNames, Role} from "../../features/weerwolven/data/Role";
import {roleOverviewPageId, warningPageId} from "./PreGame";
import {setDiefRole} from "../../features/weerwolven/slices/PreGameSlice";
import styles from "../styles.module.css"


export function DiefRoles(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const roles = useAppSelector(selectRoles)
    const diefRoles = useAppSelector(selectDiefRoles)
    const dispatch = useAppDispatch()

    function getRolesToDisplay(): JSX.Element {
        let rolesCopy = [...roles]
        let result: JSX.Element[] = []
        let usedRoles: boolean[] = Array(2).fill(false)
        for (let index = 0; index < roles.length; index++) {
            if (index === Role.DIEF) continue
            for (let subIndex = 0; subIndex < rolesCopy[index]; subIndex++) {
                if (subIndex >= 2) break
                let isSelected = false
                let selectionIndex = 0
                if (!usedRoles[0] && diefRoles[0] === index) {
                    usedRoles[0] = true
                    isSelected = true
                } else if (!usedRoles[1] && diefRoles[1] === index) {
                    selectionIndex = 1
                    usedRoles[1] = true
                    isSelected = true
                }
                result.push(<SelectableRole key={displayNames[index] + subIndex} role={index as Role}
                                            isSelected={isSelected} onClick={() => {
                    if (isSelected) {
                        dispatch(setDiefRole({role: Role.UNKNOWN, index: selectionIndex}))
                    } else {
                        if (diefRoles[0] === Role.UNKNOWN) {
                            dispatch(setDiefRole({role: index as Role, index: 0}))
                        } else if (diefRoles[1] === Role.UNKNOWN) {
                            dispatch(setDiefRole({role: index as Role, index: 1}))
                        }
                    }
                }}/>)
            }
        }
        return (<div>
            {result}
        </div>)
    }

    const diefRolesSelected = diefRoles[0] !== Role.UNKNOWN && diefRoles[1] !== Role.UNKNOWN
    return (<>
        <div className={styles.Breadcrumb}>Voorbereiding / Kaarten voor de Dief</div>
        <div className={`${styles.GekozenRollen} ${styles.Centered}`}>
            <p className={styles.Vraag}>Welke 2 kaarten heb je over voor de Dief om in de 1e nacht uit te kiezen?<br/></p>
            {getRolesToDisplay()}
        </div>

        <div className={styles.TerugVerder}>
            {!diefRolesSelected ? <div className={`${styles.Centered} ${styles.Smal}`}>
                <p className={`${styles.Uitleg}`}><i className={
                    `fa-solid fa-triangle-exclamation ${styles.Bigtxt}`
                }></i><br/>Selecteer 2 kaarten.<br/>Niet meer, niet minder.</p>
            </div> : ""}
            <button onClick={() => {
                window.scrollTo({ top: 0 });
                changer(roleOverviewPageId)
            }} className={styles.ButtonTerug}>Terug</button>
            <button onClick={() => {
                window.scrollTo({ top: 0 });
                changer(warningPageId)
            }} className={styles.ButtonVerder} disabled={!diefRolesSelected}>Verder</button>
        </div>
    </>)
}