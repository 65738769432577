import {NightData} from "../../data/NightData";
import {PersistentNightData} from "../../data/PersistentNightData";
import {Role} from "../../data/Role";
import {solveFirstNight} from "./first-night/SolveFirstNight";
import {solveNormalNight} from "./normal-night/SolveNormalNight";
import {WeerwolvenDeathResult} from "./Utils";

export interface NightResult {
    teamChanges: Map<string, Role>
    roleChanges: Map<string, Role>
    deaths: string[]
    newPersistentNightData: PersistentNightData
    weerwolvenResult: WeerwolvenDeathResult
}

export function solveNight(nightData: NightData,
                           persistentNightData: PersistentNightData,
                           currentNight: number): NightResult {
    return currentNight === 1 ?
        solveFirstNight(nightData, persistentNightData) :
        solveNormalNight(nightData, persistentNightData, currentNight)
}