import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightKlauwWolfPhase} from "./FirstNightKlauwWolfPhase";
import {NormalNightKlauwWolfPhase} from "./NormalNightKlauwWolfPhase";


export function KlauwWolfPhase() {
    const currentNight = useAppSelector(selectDayNumber)

    return currentNight === 0 ? <FirstNightKlauwWolfPhase/> : <NormalNightKlauwWolfPhase/>
}