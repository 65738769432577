import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightRaafPhase} from "./FirstNightRaafPhase";
import {NormalNightRaafPhase} from "./NormalNightRaafPhase";


export function RaafPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0

    return isFirstNight ? <FirstNightRaafPhase/> : <NormalNightRaafPhase/>
}