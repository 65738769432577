import {Player} from "./Interfaces";
import {amountOfRoles, Role} from "./Role";
import getNextPhase from "../utils/NextPhase";


export class PlayerData {
    private players: Player[] = [];
    private readonly byName: Map<string, number> = new Map()
    readonly initialRoles: number[] = Array(amountOfRoles).fill(0);
    private roles: number[] = Array(amountOfRoles).fill(0);
    private alivePlayers: number[] = [];
    private recentlyKilledPlayers: string[] = []
    groteBozeWolfActive: boolean = true

    constructor(players: string[], roles: number[]) {
        players.forEach((player, index) => {
            this.players[index] = {
                isDead: false,
                name: player,
                role: Role.UNKNOWN,
                team: Role.BURGER
            }
            this.byName.set(player, index)
            this.roles[Role.UNKNOWN]++
            this.alivePlayers.push(index)
        })
        this.initialRoles = roles
        this.roles[Role.WITTE_WEERWOLF] = roles[Role.WITTE_WEERWOLF]
    }

    startSecondNight() {
        this.roles[Role.WITTE_WEERWOLF] = this.initialRoles[Role.WITTE_WEERWOLF]
    }

    endSecondNight() {
        this.roles[Role.WITTE_WEERWOLF]--
    }

    // pure
    getPlayer(name: string): Player {
        return this.players[this.byName.get(name)!]
    }

    // pure
    getTeam(team: Role): Player[] {
        switch (team) {
            case Role.BURGER:
            case Role.WEERWOLF:
            case Role.WITTE_WEERWOLF:
            case Role.FLUITSPELER:
                return this.getAlivePlayers().filter((player) => player.team === team);
            default:
                return [];
        }
    }

    setRole(player: string, role: Role) {
        const id = this.byName.get(player)
        if (id === undefined || this.players[id] === undefined) {
            console.log(`${player} is undefined`)
            return
        }
        if (this.players[id].isDead) return;
        const previousRole = this.players[id].role
        this.players[id].role = role
        this.roles[previousRole]--
        this.roles[role]++
    }

    setTeam(player: string, team: Role) {
        switch (team) {
            case Role.BURGER:
            case Role.WEERWOLF:
            case Role.WITTE_WEERWOLF:
            case Role.FLUITSPELER:
                const id = this.byName.get(player)
                if (id === undefined || this.players[id] === undefined) {
                    console.log(`${player} is undefined`)
                    return
                }
                if (this.players[id].isDead) return;
                this.players[id].team = team
        }
    }

    // pure
    getAlivePlayers(): Player[] {
        return this.players.filter(player => !player.isDead)
    }

    // pure
    isPlayerDead(name: string): boolean {
        return this.players[this.byName.get(name)!].isDead
    }

    // pure
    getRoles(): number[] {
        return [...this.roles]
    }

    killPlayer(player: string) {
        this._killPlayer(this.byName.get(player)!)
    }

    private _killPlayer(playerId: number) {
        if (!this.alivePlayers.includes(playerId)) return
        this.roles[this.players[playerId].role]--
        this.recentlyKilledPlayers.push(this.players[playerId].name)
        this.alivePlayers = this.alivePlayers.filter((thisId) => thisId !== playerId)
        this.players[playerId].isDead = true
        if (this.players[playerId].team === Role.WEERWOLF || this.players[playerId].team === Role.WITTE_WEERWOLF) {
            this.groteBozeWolfActive = false
        }
    }

    checkLovers(lovers: string[]) {
        let isLoverDead = false
        const loverIds = lovers.map(lover => this.byName.get(lover)!)
        loverIds.forEach((lover) => {
            isLoverDead ||= !this.alivePlayers.includes(lover)
        })
        if (isLoverDead) {
            loverIds.forEach((lover) => {
                if (this.alivePlayers.includes(lover)) {
                    this._killPlayer(lover)
                }
            })
        }
    }

    // pure
    getNextNightPhase(currentPhase: Role, night: number): Role {
        if (night !== 0) {
            return getNextPhase(this.roles, currentPhase, night)
        } else {
            return getNextPhase(this.initialRoles, currentPhase, night)
        }
    }

    getPlayersByRole(role: Role): Player[] {
        return this.players.filter(player => player.role === role && !player.isDead)
    }

    getRecentlyKilledPlayers(): Player[] {
        return  this.recentlyKilledPlayers.map(player => {
            return this.getPlayer(player)
        })
    }

    clearRecentlyKilledPlayers() {
        this.recentlyKilledPlayers = []
    }

    static getFromData(data: PlayerDataInterface): PlayerData {
        let playerData = new PlayerData(data.byName, data.initialRoles)
        playerData.players = data.players
        playerData.roles = data.roles
        playerData.alivePlayers = data.alivePlayers
        playerData.recentlyKilledPlayers = data.recentlyKilledPlayers

        return playerData
    }

    mapToData(): PlayerDataInterface {
        let playerIds: string[] = []
        this.byName.forEach((id, player) => {
            playerIds[id] = player
        })
        return structuredClone({
            players: this.players,
            roles: this.roles,
            recentlyKilledPlayers: this.recentlyKilledPlayers,
            alivePlayers: this.alivePlayers,
            byName: playerIds,
            initialRoles: this.initialRoles
        })
    }

    getWeerwolven() {
        return [...this.getTeam(Role.WEERWOLF), ...this.getTeam(Role.WITTE_WEERWOLF)]
    }
}


export interface PlayerDataInterface {
    readonly players: Player[]
    readonly byName: string[]
    readonly initialRoles: number[]
    readonly roles: number[]
    readonly alivePlayers: number[]
    readonly recentlyKilledPlayers: string[]
}