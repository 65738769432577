import React, {useMemo} from "react";
import {useAppDispatch} from "../../app/hooks";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";
import {load} from "../../features/weerwolven/slices/PreGameSlice";
import {
    isGameSaveToLoadStorageId,
    load as loadGame,
    setOpenedHeaderTab
} from "../../features/weerwolven/slices/GameSlice"
import styles from "../styles.module.css"
import cirkel from "../logos/roles/cirkel40.png"
import {AppEndTag} from "../common/AppEndTag";


export function MainMenu() {
    const dispatch = useAppDispatch()
    const isGameSafeToLoad = useMemo(() => {
        return localStorage.getItem(isGameSaveToLoadStorageId) === "true"
    }, [])


    return (
        <div>
            <header className={styles.AppHeader}>
                <div className={styles.HeaderLogo}> <img src={cirkel} alt={"Weerwolven"}/> </div>
            </header>
            <div className={styles.AppBody}>


                <div className={styles.VersionNumber}>v0.1.0+25</div>


                <div>
                    <div className={styles.Centered}>
                        <h1>Weerwolven <br/>Spelleider App</h1>
                    </div>
                    <div className={styles.Centered}><button className={styles.ButtonLinkLike} onClick={() => {
                        dispatch(setLocation('explanation'))
                    }}>Korte uitleg</button></div>
                    <div className={styles.Centered}><button className={styles.NieuwSpel} onClick={() => {
                        dispatch(load())
                        dispatch(setLocation('preGame'))
                    }}>Begin nieuw spel</button></div>
                    <div className={styles.Centered}><button className={styles.ButtonGeneral} onClick={() => {
                        dispatch(loadGame())
                        dispatch(setLocation("game"))
                        dispatch(setOpenedHeaderTab("none"))
                    }} disabled={!isGameSafeToLoad}>Ga door met lopend spel</button></div>
                    {/*<div className={styles.Centered}><button className={styles.ButtonGeneral} onClick={() => {*/}

                    {/*}} disabled={true}>Bekijk vorige spellen</button></div>*/}

                    <div className={`${styles.Spacer} ${styles.Twee}`}></div>
                    <div className={`${styles.Centered} ${styles.Smal}`}>
                        <p className={`${styles.Uitleg}`}><br/>Wil je de ontwikkeling van deze app ondersteunen?<br/>
                            <a href="https://www.weerwolvenspelleider.nl/doneer">Doneer via Tikkie</a></p></div>
                    <div className={`${styles.Spacer} ${styles.Twee}`}></div>

                </div>
                <AppEndTag/>
            </div>
        </div>
    )
}