import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData,
    selectPersistentNightData
} from "../../../features/weerwolven/slices/GameSelectors";
import {
    moveToNextPhase,
    moveToPreviousPhase, setNightData,
} from "../../../features/weerwolven/slices/GameSlice";

export function NormalNightKlauwWolfPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const dispatch = useAppDispatch()
    const cupidoIsTargeted = nightData.weerwolven.currentWeerwolvenTarget === persistentNightData.cupido


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Klauw wolf</div>
        <TextBalloon text={<>De Klauw wolf mag wakker worden.</>}/>

        {persistentNightData.canKlauwWolfKlauw ? <>
            <Action text={<><p>Vraag met gebaren of de Klauw wolf deze nacht wil klauwen.</p></>}/>

            <p className={`${styles.Vraag}`}>Klauwt de Klauw wolf?<br/>
                <button className={`${styles.JaNee} ${nightData.klauwWolf.doesKlauwWolfInfect ? styles.Selected : ""}`}
                        disabled={!persistentNightData.canKlauwWolfKlauw} onClick={() => {
                    nightData.klauwWolf.doesKlauwWolfInfect = true;
                    dispatch(setNightData(nightData));
                }}>Ja
                </button>
                <button className={`${styles.JaNee} ${!nightData.klauwWolf.doesKlauwWolfInfect ? styles.Selected : ""}`}
                        onClick={() => {
                            nightData.klauwWolf.doesKlauwWolfInfect = false;
                            dispatch(setNightData(nightData));
                        }}>Nee
                </button>
            </p>
        </>: <div className={styles.Uitleg}>De Klauw wolf heeft al geklauwd.</div>}

        {cupidoIsTargeted ? <div className={styles.Uitleg}>De Weerwolven hebben Cupido aangevallen en de Klauw wolf
            kan niet klauwen.</div> : <></>}

        <TextBalloon text={<>De Klauw wolf gaat weer slapen.</>}/>

        {nightData.klauwWolf.doesKlauwWolfInfect ? <Action text={<><p>Geef deze persoon een zacht klauwtje op de knie:</p>
            <p>
                <button className={styles.NaamWeergave}>{nightData.weerwolven.currentWeerwolvenTarget}</button>
            </p>
        </>}/> : ""}

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}