import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData,
    selectPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function NormalNightWeerwolvenPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const playerData = useAppSelector(selectPlayers)
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Weerwolven</div>

        <TextBalloon text={<>De Weerwolven mogen wakker worden.<br/>Weerwolven, wie willen jullie vannacht opeten?</>}/>

        <p className={`${styles.Vraag}`}>Wie wordt er door de Weerwolven aangewezen?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playerData.getTeam(Role.BURGER).map(player => {
                return <SelectablePlayer name={player.name} isSelected={
                    player.name === nightData.weerwolven.currentWeerwolvenTarget
                } key={player.name} onClick={() => {
                    nightData.weerwolven.currentWeerwolvenTarget = player.name
                    dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>De Weerwolven gaan weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.weerwolven.currentWeerwolvenTarget ===""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}