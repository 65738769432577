import React, {useEffect, useMemo} from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightVosPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const playersWithoutRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.vos.currentVos]))
    const dispatch = useAppDispatch()
    const weerwolven = useMemo(() => {
        let localWeerwolven = [...nightData.weerwolven.currentWeerwolven]
        if (nightData.klauwWolf.doesKlauwWolfInfect) {
            localWeerwolven.push(nightData.weerwolven.currentWeerwolvenTarget)
        }
        return localWeerwolven.filter((weerwolf, index, array) => {
            return array.indexOf(weerwolf) === index
        })
    }, [])


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Vos</div>

        <TextBalloon text={<>De Vos mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Vos?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.vos.currentVos}
                                         onClick={() => {
                                             nightData.vos.currentVos = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Vos, van welke speler en zijn 2 buren wil jij zien of er een Weerwolf tussen zit?</>}/>

        <p>Dit zijn de Weerwolven:</p>
        {weerwolven.map(weerwolf => {
            return <button className={`${styles.NaamWeergave}`} key={weerwolf}>{weerwolf}</button>
        })}


        <Action
            text={<><p>Geef met 3 vingers in de lucht aan dat er een Weerwolf bij zit, of met 2 vingers dat het allemaal
                goed Volk is.</p></>}/>

        <p className={`${styles.Vraag}`}>Heeft de Vos een Weerwolf gespot?<br/>
            <button className={`${styles.JaNee} ${nightData.vos.hasTheVosFoundAWerewolf ? styles.Selected : ""}`}
                    onClick={() => {
                        nightData.vos.hasTheVosFoundAWerewolf = true
                        dispatch(setNightData(nightData))
                    }}>Ja</button>
            <button className={`${styles.JaNee} ${!nightData.vos.hasTheVosFoundAWerewolf ? styles.Selected : ""}`}
                    onClick={() => {
                        nightData.vos.hasTheVosFoundAWerewolf = false
                        dispatch(setNightData(nightData))
                    }}>Nee</button>
        </p>

        <TextBalloon text={<>De Vos gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.vos.currentVos === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Verder</button>
        </div>
    </div>)
}