import React, {useMemo} from "react";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectData,
    selectDayNumber,
    selectDayPhase,
    selectPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {checkWinConditions, killPlayer, setData, setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {lynchenPageId, overlegPageId, raadsheerLynchenPageId, raadsheerPageId, schemeringPageId} from "../DayPhase";
import {Role} from "../../../features/weerwolven/data/Role";
import {AppEndTag} from "../../common/AppEndTag";
import {DisplayPlayer} from "../../common/DisplayPlayer";

export function LynchenPhase() {
    const dispatch = useAppDispatch()
    const currentDay = useAppSelector(selectDayNumber)
    const playerData = useAppSelector(selectPlayers)
    const players = playerData.getAlivePlayers()
    const data = useAppSelector(selectData)
    const dayPhase = useAppSelector(selectDayPhase)
    const roles = playerData.getRoles()
    const otherLover = useMemo(() => {
        if (data.lynchTarget === data.currentLovers[0]) {
            return data.currentLovers[1]
        } else if (data.lynchTarget === data.currentLovers[1]) {
            return data.currentLovers[0]
        }
    }, [data.lynchTarget, data.currentLovers])


    return (<div className={`${styles.AppBody} ${styles.Dag}`}>
        <div className={`${styles.Breadcrumb}`}>{currentDay}e Dag / Lynchen</div>

        <p className={`${styles.Vraag}`}>Wie wordt er gelyncht?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === data.lynchTarget}
                                         onClick={() => {
                                             data.lynchTarget = player.name
                                             dispatch(setData(data))
                                         }}/>
            })}
        </div>

        {otherLover !== undefined ? <>
            <div className={`${styles.Centered} ${styles.Smal}`}>
                <p className={styles.Uitleg}>
                    Als je deze persoon vermoordt,
                    dan gaat <DisplayPlayer name={otherLover} small={true}/> ook dood, omdat zij Geliefden zijn.</p>
            </div>
        </> : ""}

        <div className={`${styles.Spacer} ${styles.Drie}`}></div>

        <div className={`${styles.Centered} ${styles.Smal}`}>
            <p><i className="fa-solid fa-triangle-exclamation" style={{color: "#900",fontSize:"1.5em"}}></i><br/><br/>
                Deze persoon DEFINITIEF vermoorden? Klik alleen op Verder als je het zeker weet.</p>
        </div>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                if (dayPhase === raadsheerLynchenPageId) {
                    dispatch(setDayPhase(raadsheerPageId))
                } else if (dayPhase === lynchenPageId) {
                    dispatch(setDayPhase(overlegPageId))
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={data.lynchTarget === ""} onClick={() => {
                if (dayPhase === lynchenPageId && roles[Role.STOTTERENDE_RAADSHEER] > 0 && data.raadsheerCanVeto) {
                    dispatch(setDayPhase(raadsheerPageId))
                } else {
                    dispatch(setDayPhase(schemeringPageId))
                }
                dispatch(killPlayer(data.lynchTarget))
                dispatch(checkWinConditions())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}