import React from "react";
import styles from "../styles.module.css"


export function AppFooter() {
    return (
        <footer className={`${styles.AppFooter}`}>
            <p>
                <a href="mailto:app@weerwolvenspelleider.nl?subject=Ik heb een vraag of feedback over de app">
                    Vragen of feedback</a> • <a href="https://www.weerwolvenspelleider.nl/rollen-info">
                Rollen info</a></p>
        </footer>

    )
}