import React from "react";
import {useAppSelector} from "../../app/hooks";
import {selectDayPhase} from "../../features/weerwolven/slices/GameSelectors";
import {OchtendglorenPhase} from "./ochtendgloren/OchtendglorenPhase";
import {OchtendPhase} from "./ochtend/OchtendPhase";
import {BurgemeesterPhase} from "./burgemeester/BurgemeesterPhase";
import {OverlegPhase} from "./overleg/OverlegPhase";
import {LynchenPhase} from "./lynchen/LynchenPhase";
import {RaadsheerDayPhase} from "./raadsheer/RaadsheerDayPhase";
import {SchemeringPhase} from "./schemering/SchemeringPhase";

export const ochtendGlorenPageId = 0
export const ochtendPageId = 1
export const burgemeesterPageId = 2
export const overlegPageId = 3
export const lynchenPageId = 4
export const raadsheerPageId = 5
export const raadsheerLynchenPageId = 6
export const schemeringPageId = 7

export function DayPhase() {
    const dayPhase = useAppSelector(selectDayPhase)

    switch (dayPhase) {
        case ochtendGlorenPageId:
            return <OchtendglorenPhase/>
        case ochtendPageId:
            return <OchtendPhase/>
        case burgemeesterPageId:
            return <BurgemeesterPhase/>
        case overlegPageId:
            return <OverlegPhase/>
        case lynchenPageId:
        case raadsheerLynchenPageId:
            return <LynchenPhase/>
        case raadsheerPageId:
            return <RaadsheerDayPhase/>
        case schemeringPageId:
            return <SchemeringPhase/>
        default:
            return <>wtf happened</>
    }
}