import {PlayerData, PlayerDataInterface} from "./PlayerData";
import {Role} from "./Role";
import {Player, PreGameState} from "./Interfaces";
import {getNightPhases} from "../utils/NextPhase";
import {initialNormalData, NormalData} from "./NormalData";
import {initialNightData, NightData} from "./NightData";
import {initialPersistentNightData, PersistentNightData} from "./PersistentNightData";
import {solveNight} from "../utils/night-solver/SolveNight";
import {ochtendGlorenPageId} from "../../../design/day/DayPhase";
import {WeerwolvenDeathResult} from "../utils/night-solver/Utils";

export type HeaderTabs = "none"|"stop"|"pauze"|"bliksem"

export class Game {
    day: number = 0
    isDay: boolean = false
    players: PlayerData = new PlayerData([], [])
    totalPlayTime: number = 0
    dayPlayTime: number = 0
    phasePlayTime: number = 0
    winningTeam: Role = Role.UNKNOWN
    private phases: Role[] = []
    private currentPhase: number = 0
    dayPhase: number = 0
    data: NormalData = {...initialNormalData}
    nightData: NightData = structuredClone(initialNightData)
    persistentNightData: PersistentNightData = {...initialPersistentNightData}
    openedHeaderTab: HeaderTabs = "none"
    weerwolvenDeathResult: WeerwolvenDeathResult = {
        isWerewolvesTargetKilled: true,
        reasonOfThwart: Role.UNKNOWN,
        isCupidoKilled: false
    }

    initGame(preGameState: PreGameState) {
        this.players = new PlayerData(preGameState.players, preGameState.roles)
    }

    startNight() {
        this.nightData = structuredClone(initialNightData)
        this.isDay = false
        this.dayPlayTime = 0
        this.phasePlayTime = 0
        this.currentPhase = 0
        this.data.raadsheerCanVeto &&= !this.data.raadsheerForcedSecondVote
        this.persistentNightData.groteBozeWolfCanKill = this.players.groteBozeWolfActive
        this.phases = getNightPhases(this.day === 0 ? this.players.initialRoles : this.players.getRoles(),
            this.day)
        this.setWinningTeam()
        if (this.day === 1) {
            this.players.startSecondNight()
        }
    }

    endNight() {
        this.players.clearRecentlyKilledPlayers()
        this.isDay = true
        this.day += 1
        this.dayPlayTime = 0
        this.phasePlayTime = 0
        const nightResult = solveNight(this.nightData, this.persistentNightData, this.day)
        nightResult.roleChanges.forEach((role, player) => {
            this.players.setRole(player, role)
        })
        nightResult.teamChanges.forEach((team, player) => {
            this.players.setTeam(player, team)
        })
        nightResult.deaths.forEach(player => {
            this.players.killPlayer(player)
        })
        this.weerwolvenDeathResult = nightResult.weerwolvenResult
        this.persistentNightData = nightResult.newPersistentNightData
        this.players.checkLovers(this.data.currentLovers)
        this.checkWildemanIdol()
        this.data.raadsheerForcedSecondVote = false
        this.data.lynchTarget = ""
        this.dayPhase = ochtendGlorenPageId
        if (this.day === 1) {
            this.data.titus = this.nightData.titus.currentTitus
        } else if (this.day === 2) {
            this.players.endSecondNight()
        }
        this.setWinningTeam()
    }

    killPlayer(player: string) {
        this.players.killPlayer(player)
    }

    incrementPlayTime() {
        this.totalPlayTime++
        this.dayPlayTime++
        this.phasePlayTime++
    }

    clearPhasePlayTime() {
        this.phasePlayTime = 0
    }

    clearDayPlayTime() {
        this.dayPlayTime = 0
    }

    moveToNextPhase() {
        if (this.currentPhase < (this.phases.length - 1)) {
            this.currentPhase++
        }
    }

    moveToPreviousPhase() {
        if (this.currentPhase > 0) {
            this.currentPhase--
        }
    }

    getCurrentPhase() {
        if (this.currentPhase === this.phases.length) {
            return Role.UNKNOWN
        }
        return this.phases[this.currentPhase]
    }

    getAmountOfCardsForRole(role: Role) {
        return this.day === 0 ?

            this.players.initialRoles[role] -
                (this.persistentNightData.diefDiscardedRole === role ?
                    1 : 0)

            : this.players.getRoles()[role]
    }

    isCurrentPhaseFirstPhase() {
        return this.currentPhase === 0
    }

    getPlayersWithUnknownRole(withPlayers: string[]) {
        // all roles for the first night
        const currentNightAssignedRoles = [this.nightData.vos.currentVos,
        this.nightData.ziener.currentZiener, this.nightData.currentStamOudste, this.nightData.titus.currentTitus,
        ...this.nightData.weerwolven.currentWeerwolven, ...this.nightData.siblings.currentBroertjes,
            ...this.nightData.siblings.currentZusjes, this.nightData.groteBozeWolf.currentGroteBozeWolf,
            this.nightData.raadsheer.currentRaadsheer, this.nightData.wildeman.currentWildeman,
        this.nightData.klauwWolf.currentKlauwWolf, this.nightData.heks.currentHeks,
        this.nightData.raaf.currentRaaf, this.persistentNightData.cupido, this.nightData.genezer.currentGenezer]
            .filter((playerName) => !withPlayers.includes(playerName))


        return this.players.getAlivePlayers().filter((player) => {
            return player.role === Role.UNKNOWN && !currentNightAssignedRoles.includes(player.name)
        })
    }

    cloneGameFromOtherInstance(game: Game) {
        this.players = game.players
        this.day = game.day
        this.isDay = game.isDay
        this.totalPlayTime = game.totalPlayTime
        this.dayPlayTime = game.dayPlayTime
        this.phasePlayTime = game.phasePlayTime
        this.winningTeam = game.winningTeam
        this.phases = game.phases
        this.currentPhase = game.currentPhase
        this.data = game.data
        this.nightData = game.nightData
        this.persistentNightData = game.persistentNightData
    }

    checkWildemanIdol() {
        const wildemanIdol = this.players.getPlayer(this.persistentNightData.wildemanIdol)
        if (wildemanIdol === undefined) return
        const wildeMan: Player|undefined = this.players.getPlayersByRole(Role.WILDEMAN)[0]
        if (wildemanIdol.isDead && wildeMan !== undefined) {
            this.players.setTeam(wildeMan.name, Role.WEERWOLF)
        }
    }

    getGameData(): GameData {
        return structuredClone({
            day: this.day,
            data: this.data,
            currentPhase: this.currentPhase,
            isDay: this.isDay,
            phases: this.phases,
            totalPlayTime: this.totalPlayTime,
            nightData: this.nightData,
            persistentNightData: this.persistentNightData,
            dayPlayTime: this.dayPlayTime,
            players: this.players.mapToData(),
            phasePlayTime: this.phasePlayTime,
            winningTeam: this.winningTeam,
            dayPhase: this.dayPhase,
            openedHeaderTag: this.openedHeaderTab,
            werewolvesResult: this.weerwolvenDeathResult
        })
    }

    static getGameFromData(gameData: GameData): Game {
        let game = new Game()
        game.data = gameData.data
        game.nightData = gameData.nightData
        game.persistentNightData = gameData.persistentNightData
        game.day = gameData.day
        game.isDay = gameData.isDay
        game.players = PlayerData.getFromData(gameData.players)
        game.totalPlayTime = gameData.totalPlayTime
        game.dayPlayTime = gameData.dayPlayTime
        game.phasePlayTime = gameData.phasePlayTime
        game.winningTeam = gameData.winningTeam
        game.phases = gameData.phases
        game.currentPhase = gameData.currentPhase
        game.dayPhase = gameData.dayPhase
        game.openedHeaderTab = gameData.openedHeaderTag
        game.weerwolvenDeathResult = gameData.werewolvesResult
        return game
    }

    getPhaseAt(index: number): Role {
        return this.phases[index]
    }

    setWinningTeam() {
        if (this.day === 0) return
        const weerwolven = this.players.getTeam(Role.WEERWOLF).length > 0 ? 1 : 0
        const burgers = this.players.getTeam(Role.BURGER).length > 0 ? 1 : 0
        const witteWeerwolf = this.players.getTeam(Role.WITTE_WEERWOLF).length > 0 ? 1 : 0
        this.winningTeam = Role.UNKNOWN
        if ((weerwolven + burgers + witteWeerwolf) === 1) {
            if (witteWeerwolf === 1) {this.winningTeam = Role.WITTE_WEERWOLF}
            if (weerwolven === 1) this.winningTeam = Role.WEERWOLF
            if (burgers === 1) this.winningTeam = Role.BURGER
            return;
        }
        if (this.players.getAlivePlayers().length === 2 && this.data.currentLovers.length === 2) {
            if (!this.players.getPlayer(this.data.currentLovers[0]).isDead) {
                this.winningTeam = Role.CUPIDO
            }
        }
    }
}

export interface GameData {
    readonly day: number
    readonly isDay: boolean
    readonly players: PlayerDataInterface
    readonly totalPlayTime: number
    readonly dayPlayTime: number
    readonly phasePlayTime: number
    readonly winningTeam: Role
    readonly phases: Role[]
    readonly currentPhase: number
    readonly data: NormalData
    readonly nightData: NightData
    readonly persistentNightData: PersistentNightData
    readonly dayPhase: number
    readonly openedHeaderTag: HeaderTabs
    readonly werewolvesResult: WeerwolvenDeathResult
}