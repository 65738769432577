import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightVosPhase} from "./FirstNightVosPhase";
import {NormalNightVosPhase} from "./NormalNightVosPhase";


export function VosPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0

    return isFirstNight ? <FirstNightVosPhase/> : <NormalNightVosPhase/>
}