import React from "react";
import {displayNames, Role} from "../../features/weerwolven/data/Role";
import styles from "../styles.module.css"
import broertjesLogo from "../logos/roles/broertjes.gif"
import burgerLogo from "../logos/roles/burger.gif"
import burgerBurgerLogo from "../logos/roles/burgerlijke-burger.gif"
import cupidoLogo from "../logos/roles/cupido.gif"
import raafLogo from "../logos/roles/raaf.gif"
import diefLogo from "../logos/roles/dief.gif"
import dienstmeisjeLogo from "../logos/roles/dienstmeisje.gif"
import dorpsgekLogo from "../logos/roles/dorpsgek.gif"
import fluitspelerLogo from "../logos/roles/fluitspeler.gif"
import genezerLogo from "../logos/roles/genezer.gif"
import groteBozeWolfLogo from "../logos/roles/grote-boze-wolf.gif"
import heksLogo from "../logos/roles/heks.gif"
import jagerLogo from "../logos/roles/jager.gif"
import klauwWolfLogo from "../logos/roles/klauwwolf.gif"
import stotterendeRaadsheerLogo from "../logos/roles/stotterende-raadsheer.gif"
import stamoudsteLogo from "../logos/roles/stamoudste.gif"
import toneelspelerLogo from "../logos/roles/toneelspeler.gif"
import titusLogo from "../logos/roles/titus.gif"
import cirkelGIF from "../logos/roles/cirkel.gif"
import vosLogo from "../logos/roles/vos.gif"
import weerwolfLogo from "../logos/roles/weerwolf.gif"
import wildemanLogo from "../logos/roles/wildeman.gif"
import witteWeerwolfLogo from "../logos/roles/witte-weerwolf.gif"
import zienerLogo from "../logos/roles/ziener.gif"
import zondebokLogo from "../logos/roles/zondebok.gif"
import zusjesLogo from "../logos/roles/zusjes.gif"
import ridderLogo from "../logos/roles/ridder-met-het-roestige-zwaard.gif"
import spiekendeMeisjeLogo from "../logos/roles/spiekende-meisje.gif"
import wolfshondLogo from "../logos/roles/wolfshond.gif"


export function SelectableRole(props: {role: Role, isSelected: boolean, onClick: () => void}) {
    return (
        <button onClick={props.onClick} className={props.isSelected ? styles.Selected : ""
        }><span className={styles.Rand}>
            <img src={getRolePicture(props.role)} alt={displayNames[props.role]}/>
        </span>{displayNames[props.role]}</button>
    )
}

export function getRolePicture(role: Role): string {
    switch (role) {
        case Role.BROERTJE:
            return broertjesLogo
        case Role.BURGER:
            return burgerLogo
        case Role.BURGERLIJKE_BURGER:
            return burgerBurgerLogo
        case Role.CUPIDO:
            return cupidoLogo
        case Role.DE_RAAF:
            return raafLogo
        case Role.DIEF:
            return diefLogo
        case Role.DIENSTMEISJE:
            return dienstmeisjeLogo
        case Role.DORPSGEK:
            return dorpsgekLogo
        case Role.FLUITSPELER:
            return fluitspelerLogo
        case Role.GENEZER:
            return genezerLogo
        case Role.GROTE_BOZE_WOLF:
            return groteBozeWolfLogo
        case Role.HEKS:
            return heksLogo
        case Role.JAGER:
            return jagerLogo
        case Role.KLAUW_WOLF:
            return klauwWolfLogo
        case Role.STAM_OUDSTE:
            return stamoudsteLogo
        case Role.STOTTERENDE_RAADSHEER:
            return stotterendeRaadsheerLogo
        case Role.TITUS:
            return titusLogo
        case Role.TONEELSPELER:
            return toneelspelerLogo
        case Role.UNKNOWN:
            return cirkelGIF
        case Role.VOS:
            return vosLogo
        case Role.WEERWOLF:
            return weerwolfLogo
        case Role.WILDEMAN:
            return wildemanLogo
        case Role.WITTE_WEERWOLF:
            return witteWeerwolfLogo
        case Role.ZIENER:
            return zienerLogo
        case Role.ZONDEBOK:
            return zondebokLogo
        case Role.ZUSJE:
            return zusjesLogo
        case Role.RIDDER_MET_HET_ROESTIGE_ZWAARD:
            return ridderLogo
        case Role.SPIEKENDE_MEISJE:
            return spiekendeMeisjeLogo
        case Role.WOLFS_HOND:
            return wolfshondLogo
    }
}