import React from "react";
import styles from "../styles.module.css"
import {
    gameStorageId,
    isGameSaveToLoadStorageId,
    setOpenedHeaderTab,
    stopGame
} from "../../features/weerwolven/slices/GameSlice";
import {useAppDispatch} from "../../app/hooks";
import {AppEndTag} from "./AppEndTag";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";

export function StopScreen() {
    const dispatch = useAppDispatch()


    return (<div className={styles.AppBody}>
        <div className={`${styles.Breadcrumb}`}>Stop</div>

        <div className={`${styles.Centered} ${styles.Smal}`}>
            <p>Dit spel definitief stoppen...</p>
            <p className={`${styles.Uitleg}`}><br/><i className="fa-solid fa-triangle-exclamation"
                                                      style={{color: "#900",fontSize:"2em"}}></i><br/><br/>
                Wanneer je op STOP SPEL klikt stopt het spel definitief. Er wordt niets opgeslagen en niets
                bewaard.<br/><br/></p>
        </div>


        <div className={`${styles.Centered}`}>
            <button className={`${styles.ButtonGeneral} ${styles.Rood}`} onClick={() => {
                localStorage.setItem(gameStorageId, "")
                localStorage.setItem(isGameSaveToLoadStorageId, "false")
                dispatch(setLocation("main"))
            }}>STOP SPEL</button>
        </div>


        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(setOpenedHeaderTab("none"))
            }}>Terug naar het spel</button>
        </div>
        <AppEndTag/>
    </div>)
}