import {Role} from "../../data/Role";

export function solveWereWolfKill(factors: {
    cupido: string
    cupidoBedPartner: string // set to "" if cupido rests at his own place
    werewolvesTarget: string
    genezerTarget: string
    doesHeksHeal: boolean
    stamoudste: string
    isStamoudsteProtected: boolean
    doesKlauwWolfKlauw: boolean
}): WeerwolvenDeathResult {
    const isCupidoKilled: boolean = factors.cupidoBedPartner === factors.werewolvesTarget
    if (factors.cupido === factors.werewolvesTarget && factors.cupidoBedPartner !== "") {
        return {
            isWerewolvesTargetKilled: false,
            reasonOfThwart: Role.CUPIDO,
            isCupidoKilled: isCupidoKilled
        }
    }
    if (factors.doesKlauwWolfKlauw) {
        return {
            isWerewolvesTargetKilled: false,
            reasonOfThwart: Role.KLAUW_WOLF,
            isCupidoKilled: isCupidoKilled
        }
    }
    if (factors.genezerTarget === factors.werewolvesTarget) {
        return {
            isWerewolvesTargetKilled: false,
            reasonOfThwart: Role.GENEZER,
            isCupidoKilled: isCupidoKilled
        }
    }
    if (factors.isStamoudsteProtected && (factors.stamoudste === factors.werewolvesTarget)) {
        return {
            isWerewolvesTargetKilled: false,
            reasonOfThwart: Role.STAM_OUDSTE,
            isCupidoKilled: isCupidoKilled
        }
    }
    if (factors.doesHeksHeal) {
        return {
            isWerewolvesTargetKilled: false,
            reasonOfThwart: Role.HEKS,
            isCupidoKilled: isCupidoKilled
        }
    }



    return {
        isWerewolvesTargetKilled: true,
        reasonOfThwart: Role.UNKNOWN,
        isCupidoKilled: isCupidoKilled
    }
}

export interface WeerwolvenDeathResult {
    isWerewolvesTargetKilled: boolean
    reasonOfThwart: Role
    isCupidoKilled: boolean
}