import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectAmountOfCardsForRole,
    selectData,
    selectDayNumber,
    selectRecentlyKilledPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {burgemeesterPageId, ochtendGlorenPageId, overlegPageId} from "../DayPhase";
import {AppEndTag} from "../../common/AppEndTag";
import {selectRoles} from "../../../features/weerwolven/slices/PreGameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";

export function OchtendPhase() {
    const currentDay = useAppSelector(selectDayNumber)
    const data = useAppSelector(selectData)
    const recentlyKilledPlayers = useAppSelector(selectRecentlyKilledPlayers)
    const dispatch = useAppDispatch()
    const amountOfTitus = useAppSelector(selectAmountOfCardsForRole(Role.TITUS))


    return (<div className={`${styles.AppBody} ${styles.Dag}`}>
        <div className={`${styles.Breadcrumb}`}>{currentDay}e Dag / Ochtend</div>

        {recentlyKilledPlayers.length === 0 ?

            <TextBalloon text={<>Het wordt ochtend in het dorp en iedereen wordt wakker.{amountOfTitus > 0 ?
                <p><br/><br/>De beer van Titus gromt {data.bearGrowls ? "WEL" : "NIET"}.</p> : ""}</>}/>

        :

            <TextBalloon text={<>
            <p>Het wordt ochtend in het dorp, behalve voor:</p>
            {recentlyKilledPlayers.map(player =>
                <button key={player.name} className={styles.NaamWeergave}>{player.name}</button>)}
            {amountOfTitus > 0 ? <p><br/>En de beer van Titus gromt {data.bearGrowls ? "WEL" : "NIET"}.</p> : ""}</>}/>

        }


        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(setDayPhase(ochtendGlorenPageId))
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                if (currentDay !== 1) {
                    dispatch(setDayPhase(overlegPageId))
                } else {
                    dispatch(setDayPhase(burgemeesterPageId))
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}