import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {burgemeesterPageId, lynchenPageId, ochtendPageId} from "../DayPhase";
import {AppEndTag} from "../../common/AppEndTag";

export function OverlegPhase() {
    const currentDay = useAppSelector(selectDayNumber)
    const dispatch = useAppDispatch()


    return (<div className={`${styles.AppBody} ${styles.Dag}`}>
        <div className={`${styles.Breadcrumb}`}>{currentDay}e Dag / Overleg</div>
        <p className={styles.Vraag}>Geef de dorpelingen de tijd om te overleggen.</p>

        <div className={`${styles.Spacer} ${styles.Vier}`}></div>

        <p className={`${styles.Vraag}`}>Wanneer het te lang duurt zeg je:</p>


        <TextBalloon text={<>Het begint te schemeren in het dorp.</>}/>

        <div className={`${styles.Spacer} ${styles.Vier}`}></div>
        <div className={`${styles.Spacer} ${styles.Vier}`}></div>

        <div className={`${styles.Centered} ${styles.Smal}`}>
            <p className={`${styles.Uitleg}`}>Klik op Verder wanneer er wordt gestemd over wie er deze dag gelyncht wordt.</p>
        </div>


        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                if (currentDay === 1) {
                    dispatch(setDayPhase(burgemeesterPageId))
                } else {
                    dispatch(setDayPhase(ochtendPageId))
                }
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(setDayPhase(lynchenPageId))
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}