import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {Action} from "../../common/Action";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";


export function RaadsheerPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const playersWithUnknownRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.raadsheer.currentRaadsheer]))


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Stotterende Raadsheer</div>

        <TextBalloon text={<>De Stotterende Raadsheer mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Stotterende Raadsheer?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithUnknownRole.map(player => {
                return <SelectablePlayer key={player.name} name={player.name}
                                         isSelected={player.name === nightData.raadsheer.currentRaadsheer}
                                         onClick={() => {
                                             nightData.raadsheer.currentRaadsheer = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <Action text={<><p>Spreek met gebaren een teken af voor als de Stotterende Raadsheer, aan het eind van de dag, een 2e persoon wil laten lynchen.</p></>}/>

        <TextBalloon text={<>De Stotterende Raadsheer gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.raadsheer.currentRaadsheer === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Verder</button>
        </div>
    </div>)
}