import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightGenezer} from "./FirstNightGenezer";
import {NormalNightGenezer} from "./NormalNightGenezer";


export function GenezerPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0

    return isFirstNight ? <FirstNightGenezer/> : <NormalNightGenezer/>
}