import React, {useState} from "react";
import roleAmounts, {Role} from "../../features/weerwolven/data/Role";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectPlayers, selectRoles} from "../../features/weerwolven/slices/PreGameSelectors";
import {setAmountOfCardsForRole} from "../../features/weerwolven/slices/PreGameSlice";
import {SelectableRole} from "../common/SelectableRole";
import {playerSelectionPageId, roleOverviewPageId} from "./PreGame";
import styles from "../styles.module.css"
import {RoleWithCounter} from "./RoleWithCounter";


export function RoleSelection(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const [tab, setTab] = useState('ww' as 'ww'|'burgers');
    const roles = useAppSelector(selectRoles)
    const players = useAppSelector(selectPlayers)
    const dispatch = useAppDispatch()

    function getRoleButton(role: Role) {
        return <SelectableRole role={role} isSelected={roles[role] > 0} onClick={() => {
            if (roles[role] > 0) {
                dispatch(setAmountOfCardsForRole({role: role, amount: 0}))
            } else {
                dispatch(setAmountOfCardsForRole({role: role, amount: roleAmounts[role]}))
            }
        }}/>
    }

    const amountOfWerewolves = Math.floor(players.length / 4)
    const amountOfRolesSelected = roles.reduce((previous, current) => {return previous + current}, 0)
    const amountOfRolesToSelect = players.length - amountOfRolesSelected + (roles[Role.DIEF] > 0 ? 2 : 0)
    const inCorrectAmountOfRolesSelected = amountOfRolesToSelect !== 0 || (roles[Role.WEERWOLF] +
        roles[Role.WITTE_WEERWOLF] + roles[Role.KLAUW_WOLF] + roles[Role.GROTE_BOZE_WOLF]) === 0


    const terugVerderComp = (<div className={styles.TerugVerder}>
        {inCorrectAmountOfRolesSelected ? <div className={styles.Centered + " " + styles.Smal}>
            <p className={styles.Uitleg}><i className={
                "fa-solid fa-triangle-exclamation " + styles.Bigtxt
            } style={{color:"#900"}}></i><br/>
                Je hebt nog niet het juiste aantal rollen geselecteerd.</p>
        </div> : ''}
        <button className={styles.ButtonTerug} onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            changer(playerSelectionPageId)
        }}>Terug</button>
        <button className={styles.ButtonVerder} disabled={inCorrectAmountOfRolesSelected} onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            changer(roleOverviewPageId)
        }}>Verder</button>
    </div>)

    const headerComp = (<>
        <div className={styles.Breadcrumb}>Voorbereiding / Rollen kiezen</div>
        <p className={styles.Vraag}>Met welke rollen wil je spelen?</p>
        <p className={styles.Uitleg}>We raden je aan om {
            amountOfWerewolves} of {amountOfWerewolves + 1} weerwolven rollen te kiezen.<br/><br/></p>
        <div className={styles.Centered}>
            <p>Aantal rollen nog te kiezen: <span
                className={styles.Bigtxt}>{amountOfRolesToSelect}
            </span><span className="Bigtxt">.</span>
            </p>
        </div>
    </>)

    switch (tab) {
        case "ww":
            return (<div>
                {headerComp}

                <div className={styles.WBwrapper}>
                    <hr/>
                    <button className={styles.W} disabled={true}>Weerwolf rollen</button>
                    <button className={styles.B} onClick={() => {
                        setTab("burgers")
                    }}>Burger rollen</button>
                </div>

                <div className={styles.RollenKiezen + " " + styles.Centered}>
                <RoleWithCounter role={Role.WEERWOLF}/>

                {getRoleButton(Role.GROTE_BOZE_WOLF)}
                {getRoleButton(Role.KLAUW_WOLF)}
                {getRoleButton(Role.WITTE_WEERWOLF)}
                {getRoleButton(Role.WILDEMAN)}
                {getRoleButton(Role.WOLFS_HOND)}
                </div>
                {terugVerderComp}
            </div>)
        case 'burgers':
            return (<div>
                {headerComp}

                <div className={styles.WBwrapper}>
                    <hr/>
                    <button className={styles.W} onClick={() => {
                        setTab("ww")
                    }}>Weerwolf rollen</button>
                    <button className={styles.B} disabled={true}>Burger rollen</button>
                </div>

                <div className={styles.RollenKiezen + " " + styles.Centered}>
                <RoleWithCounter role={Role.BURGER}/>

                {getRoleButton(Role.BURGERLIJKE_BURGER)}
                    {getRoleButton(Role.ZUSJE)}
                    {getRoleButton(Role.BROERTJE)}
                    {getRoleButton(Role.CUPIDO)}
                    {getRoleButton(Role.HEKS)}
                {getRoleButton(Role.ZIENER)}
                    {getRoleButton(Role.DIEF)}
                    {getRoleButton(Role.VOS)}
                    {getRoleButton(Role.TITUS)}
                    {getRoleButton(Role.STAM_OUDSTE)}
                    {getRoleButton(Role.JAGER)}
                    {getRoleButton(Role.STOTTERENDE_RAADSHEER)}
                    {getRoleButton(Role.GENEZER)}
                    {getRoleButton(Role.DE_RAAF)}
                {getRoleButton(Role.SPIEKENDE_MEISJE)}
                {getRoleButton(Role.ZONDEBOK)}
                {/*{getRoleButton(Role.RIDDER_MET_HET_ROESTIGE_ZWAARD)}*/}
                </div>

                {terugVerderComp}
            </div>)
    }
}