import React, {JSX} from "react";
import {useAppSelector} from "../../app/hooks";
import {selectRoles} from "../../features/weerwolven/slices/PreGameSelectors";
import {getRolePicture} from "../common/SelectableRole";
import {displayNames, Role} from "../../features/weerwolven/data/Role";
import {diefRolesPageId, roleSelectionPageId, warningPageId} from "./PreGame";
import styles from "../styles.module.css"
import {Action} from "../common/Action";


export function RoleOverview(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const roles = useAppSelector(selectRoles)

    function getRolesToDisplay(): JSX.Element {
        let rolesCopy = [...roles]
        let result: JSX.Element[] = []
        for (let index = 0; index < roles.length; index++) {
            for (let subIndex = 0; subIndex < rolesCopy[index]; subIndex++) {
                result.push(<img key={`${displayNames[index]} ${subIndex}`} src={getRolePicture(index as Role)}
                                 alt={displayNames[index]} width={75} height={75}/>)
            }
        }
        return (<>
            {result}
        </>)
    }

    return (<>
        <div className={styles.Breadcrumb}>Voorbereiding / Overzicht gekozen rollen</div>
        <div className={styles.GekozenRollen + " " + styles.Centered}>
            <p className={styles.Vraag}>Dit zijn de rollen die je gekozen hebt:</p>
            {getRolesToDisplay()}
        </div>
        <Action text={<>Deel deze kaarten uit aan de spelers.</>}/>
        <div className={styles.TerugVerder}>
            <button onClick={() => {
                window.scrollTo({ top: 0 });
                changer(roleSelectionPageId)
            }} className={styles.ButtonTerug}>Terug</button>
            <button onClick={() => {
                window.scrollTo({ top: 0 });
                if (roles[Role.DIEF] >= 1) {
                    changer(diefRolesPageId)
                } else {
                    changer(warningPageId)
                }
            }} className={styles.ButtonVerder}>Verder</button>
        </div>
    </>)
}