import React from "react";
import styles from "../styles.module.css"
import {save, setOpenedHeaderTab} from "../../features/weerwolven/slices/GameSlice";
import {useAppDispatch} from "../../app/hooks";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";
import {AppEndTag} from "./AppEndTag";

export function PauzeScreen() {
    const dispatch = useAppDispatch()


    return (<div className={styles.AppBody}>
        <div className={`${styles.Breadcrumb}`}>Pauze</div>

        <p className={`${styles.Uitleg}`}>Hier kun je het spel tijdelijk pauzeren. Je gaat dan naar het Startscherm. Wil je later verder spelen, dan klik je in het Startscherm op de knop 'Ga door met lopend spel'.</p>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        <div className={`${styles.Centered}`}>
            <button className={`${styles.ButtonGeneral}`} onClick={() => {
                dispatch(save("true"))
                dispatch(setLocation("main"))
            }}>Spel pauzeren</button>
        </div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(setOpenedHeaderTab("none"))
            }}>Terug naar het spel</button>
        </div>
        <AppEndTag/>
    </div>)
}