import React from "react";
import styles from "../styles.module.css";
import {SelectableRole} from "../common/SelectableRole";
import roleAmounts, {Role} from "../../features/weerwolven/data/Role";
import {setAmountOfCardsForRole} from "../../features/weerwolven/slices/PreGameSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectRoles} from "../../features/weerwolven/slices/PreGameSelectors";

export function RoleWithCounter(props: {role: Role}) {
    const dispatch = useAppDispatch()
    const roles = useAppSelector(selectRoles)


    return (
        <div className={styles.TellerWrapper}>
            <div className={styles.MetTeller}>
                <div className={styles.RolBijTeller}>
                    <SelectableRole role={props.role} isSelected={roles[props.role] > 0} onClick={() => {}}/>
                </div>
            </div>
            <div className={styles.Teller}>
                <button className={styles.MinPlus} onClick={() => {
                    if (roles[props.role] > 0) {
                        dispatch(setAmountOfCardsForRole(
                            {role: props.role, amount: roles[props.role] - 1}))
                    }
                }}>-</button>
                <span className={styles.Cijfer}>{roles[props.role]}</span>
                <button className={styles.MinPlus} onClick={() => {
                    if (roles[props.role] < roleAmounts[props.role]) {
                        dispatch(setAmountOfCardsForRole(
                            {role: props.role, amount: roles[props.role] + 1}))
                    }
                }}>+</button>
            </div>
        </div>
    )
}