import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ControlState {
    location: 'main'|'preGame'|'game'|'explanation'
    subLocation: number
}

const initialState: ControlState = {location: 'main', subLocation: 0}

export const controlSlice = createSlice({
    name: 'control',
    initialState: initialState,
    reducers: {
        setLocation: (state, action: PayloadAction<typeof initialState.location>) => {
            state.location = action.payload
            state.subLocation = 0
        },
        setSubLocation(state, action: PayloadAction<number>) {
            state.subLocation = action.payload
        }
    }
})

export const {setLocation, setSubLocation} = controlSlice.actions

export default controlSlice.reducer