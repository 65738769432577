import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectNightData,
    selectPersistentNightData,
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {
    moveToNextPhase,
    moveToPreviousPhase,
    setNightData,
} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightKlauwWolfPhase() {
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const persistentNightData = useAppSelector(selectPersistentNightData)


    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Klauw wolf</div>

        <TextBalloon text={<>De Klauw wolf mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Klauw wolf?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {nightData.weerwolven.currentWeerwolven.map(player => {
                return <SelectablePlayer name={player} isSelected={player === nightData.klauwWolf.currentKlauwWolf}
                                         key={player} onClick={() => {
                                             nightData.klauwWolf.currentKlauwWolf = player
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <Action text={<><p>Vraag met gebaren of de Klauw wolf deze nacht wil klauwen.</p></>}/>

        <p className={`${styles.Vraag}`}>Klauwt de Klauw wolf?<br/>
            <button className={`${styles.JaNee} ${nightData.klauwWolf.doesKlauwWolfInfect ? styles.Selected : ""}`}
                    disabled={!persistentNightData.canKlauwWolfKlauw} onClick={() => {
                        nightData.klauwWolf.doesKlauwWolfInfect = true
                        dispatch(setNightData(nightData))
                    }}>Ja</button>
            <button className={`${styles.JaNee} ${!nightData.klauwWolf.doesKlauwWolfInfect ? styles.Selected : ""}`}
                    onClick={() => {
                        nightData.klauwWolf.doesKlauwWolfInfect = false
                        dispatch(setNightData(nightData))
                    }}>Nee</button>
        </p>

        <TextBalloon text={<>De Klauw wolf gaat weer slapen.</>}/>

        {nightData.klauwWolf.doesKlauwWolfInfect ? <Action text={<><p>Geef deze persoon een zacht klauwtje op de knie:</p>
            <p>
                <button className={styles.NaamWeergave}>{nightData.weerwolven.currentWeerwolvenTarget}</button>
            </p>
        </>}/> : ""}

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.klauwWolf.currentKlauwWolf === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}