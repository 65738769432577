import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectData,
    selectDayNumber,
    selectNightData,
} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function LoversPhase(props: {}) {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const dispatch = useAppDispatch()
    const data = useAppSelector(selectData)

    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Geliefden</div>

        <TextBalloon text={<>Als je een tikje op je knie voelt ben je een Geliefde en mag je even wakker worden.</>}/>

        <Action text={<><p>Geef deze 2 spelers een tikje op hun knie:</p>
            <p>
                <button className={styles.NaamWeergave}>{data.currentLovers[0]}</button>
                <button className={styles.NaamWeergave}>{data.currentLovers[1]}</button>
            </p>
        </>}/>

        <TextBalloon text={<>De Geliefden kijken elkaar even aan en gaan dan weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                nightData.cupido.haveLoversMet = false
                dispatch(setNightData(nightData))
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}