import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber, selectNightData} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightCupidoPhase} from "./FirstNightCupidoPhase";
import {NormalNightCupidoPhase} from "./NormalNightCupidoPhase";
import {LoversPhase} from "./LoversPhase";


export function CupidoPhase() {
    const currentNight = useAppSelector(selectDayNumber)
    const nightData = useAppSelector(selectNightData)

    return (<>
        {currentNight === 0 ?
            nightData.cupido.haveLoversMet ? <LoversPhase /> : <FirstNightCupidoPhase/> :
            <NormalNightCupidoPhase/>}
    </>)
}