import {Role} from "./Role";

export interface PersistentNightData {
    diefRoles: Role[]
    diefDiscardedRole: Role
    cupidoLastNightBedPartner: string
    cupido: string
    stamoudste: string
    stamoudsteProtected: boolean
    genezerLastProtectedPerson: string
    canKlauwWolfKlauw: boolean
    heksCanUseHealingPotion: boolean
    heksCanUseDeathPotion: boolean
    foxCanUseAbility: boolean
    groteBozeWolfCanKill: boolean
    wildemanIdol: string
}

export const initialPersistentNightData: PersistentNightData = {
    diefRoles: [],
    diefDiscardedRole: Role.UNKNOWN,
    cupidoLastNightBedPartner: "",
    cupido: "",
    genezerLastProtectedPerson: "",
    canKlauwWolfKlauw: true,
    foxCanUseAbility: true,
    groteBozeWolfCanKill: true,
    heksCanUseDeathPotion: true,
    heksCanUseHealingPotion: true,
    wildemanIdol: "",
    stamoudste: "",
    stamoudsteProtected: true
}