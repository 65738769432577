import {Role} from "../data/Role";

export default function getNextPhase(roles: number[], currentPhase: Role, night: number): Role {
    if (night === 0) {
        return getNextValidNightPhase(roles, _getNextFirstNightPhase(currentPhase), 0)
    } else {
        return getNextValidNightPhase(roles, _getNormalNightNextPhase(night, currentPhase), night)
    }
}

function getNextValidNightPhase(roles: number[], nextPhase: Role, night: number): Role {
    if (nextPhase === Role.UNKNOWN || nextPhase === Role.WEERWOLF) return nextPhase
    if (roles[nextPhase] <= 0) {
        return getNextPhase(roles, nextPhase, night)
    }
    return nextPhase
}

function _getNextFirstNightPhase(currentPhase: Role): Role {
    switch (currentPhase) {
        case Role.UNKNOWN:
            return Role.DIEF
        case Role.DIEF:
            return Role.CUPIDO
        case Role.CUPIDO:
            return Role.GENEZER
        case Role.GENEZER:
            return Role.STAM_OUDSTE
        case Role.STAM_OUDSTE:
            return Role.WILDEMAN
        case Role.WILDEMAN:
            return Role.WEERWOLF
        case Role.WEERWOLF:
            return Role.KLAUW_WOLF
        case Role.KLAUW_WOLF:
            return Role.GROTE_BOZE_WOLF
        case Role.GROTE_BOZE_WOLF:
            return Role.HEKS
        case Role.HEKS:
            return Role.ZIENER
        case Role.ZIENER:
            return Role.VOS
        case Role.VOS:
            return Role.STOTTERENDE_RAADSHEER
        case Role.STOTTERENDE_RAADSHEER:
            return Role.ZUSJE
        case Role.ZUSJE:
            return Role.BROERTJE
        case Role.BROERTJE:
            return Role.DE_RAAF
        case Role.DE_RAAF:
            return Role.TITUS
        default:
            return Role.UNKNOWN;
    }
}

function _getNormalNightNextPhase(night: number, currentPhase: Role) {
    switch (currentPhase) {
        case Role.UNKNOWN:
            return Role.CUPIDO
        case Role.CUPIDO:
            return Role.GENEZER
        case Role.GENEZER:
            return Role.WEERWOLF
        case Role.WEERWOLF:
            return Role.KLAUW_WOLF
        case Role.KLAUW_WOLF:
            return Role.GROTE_BOZE_WOLF
        case Role.GROTE_BOZE_WOLF:
            if ((night % 2) === 1) {
                return Role.WITTE_WEERWOLF
            } else {
                return Role.HEKS
            }
        case Role.WITTE_WEERWOLF:
            return Role.HEKS
        case Role.HEKS:
            return Role.ZIENER
        case Role.ZIENER:
            return Role.VOS
        case Role.VOS:
            return Role.DE_RAAF
        default:
            return Role.UNKNOWN;
    }
}

export function getNightPhases(roles: number[], night: number): Role[] {
    let result: Role[] = []
    let currentPhase: Role = Role.UNKNOWN
    let nextPhase: Role = Role.BURGERLIJKE_BURGER
    while (nextPhase !== Role.UNKNOWN) {
        let tempPhase: Role = getNextPhase(roles, currentPhase, night)
        currentPhase = tempPhase
        nextPhase = tempPhase
        result.push(nextPhase)
    }
    return result
}