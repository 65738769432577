import { RootState } from "../../../app/store";

// template: export const name = (state: RootState) => state.preGame

export const selectDuplicateNameEntered = (state: RootState) => state.preGame.duplicateNameEntered

export const selectPlayers = (state: RootState) => state.preGame.players

export const selectRoles = (state: RootState) => state.preGame.roles

export const selectSavedPlayers = (state: RootState) => state.preGame.savedPlayers

export const selectPreGameState = (state: RootState) => state.preGame

export const selectDiefRoles = (state: RootState) => state.preGame.diefRoles