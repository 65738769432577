import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData,
    selectPersistentNightData, selectPlayers,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {
    moveToNextPhase,
    moveToPreviousPhase,
    setNightData,
    setPersistentNightData
} from "../../../features/weerwolven/slices/GameSlice";


export function WildemanPhase() {
    const nightData = useAppSelector(selectNightData)
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const dispatch = useAppDispatch()
    const playersWithoutRole = useAppSelector(selectPlayersWithoutAssignedRole([nightData.wildeman.currentWildeman]))
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)

    return <div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Wildeman</div>

        <TextBalloon text={<>De Wildeman mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Wildeman?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                return <SelectablePlayer key={player.name} name={player.name} isSelected={
                    player.name === nightData.wildeman.currentWildeman
                } onClick={() => {
                    nightData.wildeman.currentWildeman = player.name
                    dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>Wildeman, wie kies jij als jouw idool?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Wildeman aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => player.name !== nightData.wildeman.currentWildeman).map(player => {
                return <SelectablePlayer key={player.name} name={player.name} isSelected={
                    player.name === persistentNightData.wildemanIdol
                } onClick={() => {
                    if (nightData.wildeman.currentWildeman === "") return
                    persistentNightData.wildemanIdol = player.name
                    dispatch(setPersistentNightData(persistentNightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>De Wildeman gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }} disabled={isFirstPhase}>Terug</button>
            <button className={`${styles.ButtonVerder}`}
                    disabled={persistentNightData.wildemanIdol === "" ||
                        nightData.wildeman.currentWildeman === persistentNightData.wildemanIdol}
                    onClick={() => {
                dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>
}