import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber, selectNightData, selectPlayers} from "../../../features/weerwolven/slices/GameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function SecondNightWitteWeerwolfPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)

    return <div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Witte Weerwolf</div>

        <TextBalloon text={<>De Witte Weerwolf mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Witte Weerwolf?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => player.role === Role.WEERWOLF).map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.witteWolf.currentWitteWolf}
                                         onClick={() => {
                                             nightData.witteWolf.witteWolfDiedEarly = false
                                             nightData.witteWolf.currentWitteWolf = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
            <SelectablePlayer name={"De witte weerwolf is dood..."}
                              isSelected={nightData.witteWolf.witteWolfDiedEarly}
                              onClick={() => {
                                  nightData.witteWolf.witteWolfDiedEarly = !nightData.witteWolf.witteWolfDiedEarly
                                  if (nightData.witteWolf.witteWolfDiedEarly) {
                                      nightData.witteWolf.currentWitteWolf = ""
                                  }
                                  dispatch(setNightData(nightData))
                              }}/>
        </div>

        <TextBalloon text={<>Witte Weerwolf, wie van de andere Weerwolven wil jij vannacht vermoorden?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Witte Weerwolf aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => player.name !== nightData.witteWolf.currentWitteWolf
                && player.team === Role.WEERWOLF).map(player => {
                    return <SelectablePlayer name={player.name} key={player.name}
                                             isSelected={player.name === nightData.witteWolf.witteWolfTarget
                                                 && !nightData.witteWolf.witteWolfDiedEarly}
                                             onClick={() => {
                                                 nightData.witteWolf.witteWolfTarget = player.name
                                                 dispatch(setNightData(nightData))
                                             }}/>
            })}
        </div>

        <TextBalloon text={<>De Witte Weerwolf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={(nightData.witteWolf.currentWitteWolf === "" ||
                nightData.witteWolf.witteWolfTarget === "") && !nightData.witteWolf.witteWolfDiedEarly}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>
}