import React, {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {selectCurrentPhase,} from "../../features/weerwolven/slices/GameSelectors";
import {DiefPhase} from "./dief/DiefPhase";
import {CupidoPhase} from "./cupido/CupidoPhase";
import {GenezerPhase} from "./genezer/GenezerPhase";
import {StamOudstePhase} from "./stamoudste/StamOudstePhase";
import {WildemanPhase} from "./wildeman/WildemanPhase";
import {WeerwolvenPhase} from "./weerwolven/WeerwolvenPhase";
import {KlauwWolfPhase} from "./klauwwolf/KlauwWolfPhase";
import {GroteBozeWolfPhase} from "./grotebozewolf/GroteBozeWolfPhase";
import {HeksPhase} from "./heks/HeksPhase";
import {ZienerPhase} from "./ziener/ZienerPhase";
import {VosPhase} from "./vos/VosPhase";
import {RaadsheerPhase} from "./raadsheer/RaadsheerPhase";
import {ZusjesPhase} from "./zusjes/ZusjesPhase";
import {BroertjesPhase} from "./broertjes/BroertjesPhase";
import {RaafPhase} from "./raaf/RaafPhase";
import {TitusPhase} from "./titus/TitusPhase";
import {Role} from "../../features/weerwolven/data/Role";
import {EndNightWarningScreen} from "./EndNightWarningScreen";
import {WitteWolfPhase} from "./wittewolf/WitteWolfPhase";


export function NightPhase() {
    const phase = useAppSelector(selectCurrentPhase)

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [phase])

    switch (phase) {
        case Role.DIEF:
            return (<DiefPhase />)
        case Role.CUPIDO:
            return (<CupidoPhase />)
        case Role.GENEZER:
            return (<GenezerPhase />)
        case Role.STAM_OUDSTE:
            return (<StamOudstePhase />)
        case Role.WILDEMAN:
            return (<WildemanPhase />)
        case Role.WEERWOLF:
            return (<WeerwolvenPhase />)
        case Role.KLAUW_WOLF:
            return (<KlauwWolfPhase />)
        case Role.GROTE_BOZE_WOLF:
            return (<GroteBozeWolfPhase />)
        case Role.WITTE_WEERWOLF:
            return <WitteWolfPhase/>
        case Role.HEKS:
            return (<HeksPhase />)
        case Role.ZIENER:
            return (<ZienerPhase />)
        case Role.VOS:
            return (<VosPhase />)
        case Role.STOTTERENDE_RAADSHEER:
            return (<RaadsheerPhase />)
        case Role.ZUSJE:
            return (<ZusjesPhase />)
        case Role.BROERTJE:
            return (<BroertjesPhase />)
        case Role.DE_RAAF:
            return (<RaafPhase />)
        case Role.TITUS:
            return (<TitusPhase />)
        case Role.UNKNOWN:
            return <EndNightWarningScreen/>
    }

    return (<>an error occurred</>)
}