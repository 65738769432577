import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData, selectPlayers,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightGenezer() {
    const nightData = useAppSelector(selectNightData)
    const playersWithoutRole = useAppSelector(selectPlayersWithoutAssignedRole([nightData.genezer.currentGenezer]))
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const dispatch = useAppDispatch()
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)

    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Genezer</div>

        <TextBalloon text={<>De Genezer mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Genezer?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map((player) => {
                const isSelected = player.name === nightData.genezer.currentGenezer
                return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                    nightData.genezer.currentGenezer = player.name
                    dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>Genezer, wie wil jij vannacht beschermen?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Genezer aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.map((player) => {
                const isSelected = player.name === nightData.genezer.currentGenezerTarget
                return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                    nightData.genezer.currentGenezerTarget = player.name
                    dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.genezer.currentGenezer === "" ||
                nightData.genezer.currentGenezerTarget === ""
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}