import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, moveToPreviousPhase} from "../../../features/weerwolven/slices/GameSlice";

export function NormalNightRaafPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const dispatch = useAppDispatch()

    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Raaf</div>

        <TextBalloon text={<>De Raaf mag wakker worden.<br/>Raaf, bij wie wil jij je verdenking leggen?</>}/>

        <Action text={<><p>Leg de tegel van de Raaf neer bij degene die de Raaf aanwijst.</p></>}/>

        <TextBalloon text={<>De Raaf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}