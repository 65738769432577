import React from "react";
import {Action} from "../../common/Action";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectData, selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {setData, setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {raadsheerLynchenPageId, schemeringPageId} from "../DayPhase";
import {AppEndTag} from "../../common/AppEndTag";

export function RaadsheerDayPhase() {
    const currentDay = useAppSelector(selectDayNumber)
    const dispatch = useAppDispatch()
    const data = useAppSelector(selectData)


    return (<div className={`${styles.AppBody} ${styles.Dag}`}>
        <div className={`${styles.Breadcrumb}`}>{currentDay}e Dag / Stotterende Raadsheer</div>

        <Action text={<><p className={styles.Vraag}>Check: </p>
            <p>geeft de Stotterende Raadsheer het afgesproken teken?<br/>
                <button className={`${styles.JaNee} ${data.raadsheerForcedSecondVote ? styles.Selected : ""}`}
                        onClick={() => {
                            data.raadsheerForcedSecondVote = true
                            dispatch(setData(data))
                        }}>Ja</button>
                <button className={`${styles.JaNee} ${!data.raadsheerForcedSecondVote ? styles.Selected : ""}`}
                        onClick={() => {
                            data.raadsheerForcedSecondVote = false
                            dispatch(setData(data))
                        }}>Nee</button>
            </p>
        </>}/>

        {data.raadsheerForcedSecondVote ?
            <TextBalloon text={<>Ik ben zojuist geïnformeerd door de Stotterende Raadsheer
            dat er een extra stemronde gaat plaatsvinden en dat er nóg iemand zal worden gelyncht.</>}/> : ""}

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                if (data.raadsheerForcedSecondVote) {
                    dispatch(setDayPhase(raadsheerLynchenPageId))
                } else {
                    dispatch(setDayPhase(schemeringPageId))
                }
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}