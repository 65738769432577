import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightZienerPhase} from "./FirstNightZienerPhase";
import {NormalNightZienerPhase} from "./NormalNightZienerPhase";


export function ZienerPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0

    return isFirstNight ? <FirstNightZienerPhase/> : <NormalNightZienerPhase/>
}