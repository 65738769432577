export interface NormalData {
    currentRaafTarget: string
    currentLovers: string[]
    raadsheerForcedSecondVote: boolean
    lynchTarget: string
    bearGrowls: boolean
    titus: string
    raadsheerCanVeto: boolean
}

export const initialNormalData: NormalData = {
    currentLovers: [],
    currentRaafTarget: "",
    raadsheerForcedSecondVote: false,
    lynchTarget: "",
    bearGrowls: false,
    titus: "",
    raadsheerCanVeto: true
}