import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightWeerwolvenPhase} from "./FirstNightWeerwolvenPhase";
import {NormalNightWeerwolvenPhase} from "./NormalNightWeerwolvenPhase";


export function WeerwolvenPhase() {
    const currentNight = useAppSelector(selectDayNumber)

    return currentNight === 0 ? <FirstNightWeerwolvenPhase/> : <NormalNightWeerwolvenPhase/>
}