import {configureStore, ThunkAction, Action, getDefaultMiddleware} from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import gameReducer from '../features/weerwolven/slices/GameSlice';
import preGameReducer from '../features/weerwolven/slices/PreGameSlice';
import controlReducer from '../features/weerwolven/slices/ControlSlice';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        game: gameReducer,
        preGame: preGameReducer,
        control: controlReducer
    },
    middleware: defaultMiddleware => getDefaultMiddleware({
        serializableCheck: false
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
