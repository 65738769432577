import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {SelectablePlayer} from "../../common/SelectablePlayer";


export function StamOudstePhase() {
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const players = useAppSelector(selectPlayersWithoutAssignedRole([nightData.currentStamOudste]))
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)


    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Stamoudste</div>

        <TextBalloon text={<>De Stamoudste mag even de hand opsteken.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Stamoudste?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={nightData.currentStamOudste === player.name}
                                         onClick={() => {
                                             nightData.currentStamOudste = player.name
                                             dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>Dankjewel Stamoudste, ik heb je gezien.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.currentStamOudste === ""} onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}