import { RootState } from "../../../app/store";
import {Role} from "../data/Role";

// template: export const name = (state: RootState) => state.game

export const selectDayNumber = (state: RootState) => state.game.day

export const selectIsDay = (state: RootState) => state.game.isDay

export const selectTotalPlayTime = (state: RootState) => state.game.totalPlayTime

export const selectDayPlayTime = (state: RootState) => state.game.dayPlayTime

export const selectPhasePlayTime = (state: RootState) => state.game.phasePlayTime

export const selectCurrentPhase = (state: RootState) => state.game.getCurrentPhase()

export const selectIsCurrentPhaseIsFirstNightPhase = (state: RootState) => state.game.isCurrentPhaseFirstPhase()

export const selectNightData = (state: RootState) => structuredClone(state.game.nightData)

export const selectPersistentNightData = 
        (state: RootState) => structuredClone(state.game.persistentNightData)

export const selectData = (state: RootState) => structuredClone(state.game.data)

export const selectWinningTeam = (state: RootState) => state.game.winningTeam

export function selectGetPlayer(name: string) {
    return (state: RootState) => {
        return state.game.players.getPlayer(name)
    }
}

export const selectAlivePlayers = (state: RootState) => state.game.players.getAlivePlayers()

export const selectRecentlyKilledPlayers = (state: RootState) =>
        state.game.players.getRecentlyKilledPlayers()

export const selectPlayers = (state: RootState) => state.game.players

export function selectPlayersWithoutAssignedRole(withPlayer: string[]) {
    return (state: RootState) => state.game.getPlayersWithUnknownRole(withPlayer)
}

export function selectGetPlayersByRole(role: Role) {
    return (state: RootState) => state.game.players.getPlayersByRole(role)
}

export const selectDayPhase = (state: RootState) => state.game.dayPhase

export function selectAmountOfCardsForRole(role: Role) {
    return (state: RootState) => state.game.getAmountOfCardsForRole(role)
}

export const selectOpenedHeaderTab = (state: RootState) => state.game.openedHeaderTab
export const selectWeerwolvenResult = (state: RootState) => state.game.weerwolvenDeathResult