import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import {useAppDispatch} from "../../../app/hooks";
import {setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {ochtendPageId, overlegPageId} from "../DayPhase";
import {AppEndTag} from "../../common/AppEndTag";

export function BurgemeesterPhase(props: {}) {
    const dispatch = useAppDispatch()


    return (<div className={`${styles.AppBody} ${styles.Dag}`}>
        <div className={`${styles.Breadcrumb}`}>1e Dag / Burgemeester kiezen</div>

        <TextBalloon text={<>Het is tijd om een Burgemeester te kiezen. <br/><br/>Steek je hand op als je Burgemeester wilt worden.</>}/>

        <Action text={<><p>Hou een stemronde en geef de verkozen persoon de Burgemeesters Tegel.</p></>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(setDayPhase(ochtendPageId))
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(setDayPhase(overlegPageId))
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}