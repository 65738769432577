import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {amountOfRoles, Role} from "../data/Role";
import {PreGameState} from "../data/Interfaces";
import {toTitleCase} from "../utils/ToTitleCase";


const initialState: PreGameState = {
    players: [] as string[],
    savedPlayers: [] as string[],
    roles: Array(amountOfRoles).fill(0) as number[],
    duplicateNameEntered: false,
    diefRoles: [Role.UNKNOWN, Role.UNKNOWN]
}


export const preGameSlice = createSlice({
    name: 'preGame',
    initialState: () => initialState,
    reducers: {
        load: state => {
            const savedPlayers = localStorage.getItem("savedPlayers")
            if (savedPlayers === null) {
                return
            }
            state.savedPlayers = JSON.parse(savedPlayers)
        },
        save: state => {
            localStorage.setItem("savedPlayers", JSON.stringify(state.savedPlayers))
        },
        addSavedPlayer: (state, action:PayloadAction<string>) => {
            const playerName = toTitleCase(action.payload)
            if (!state.savedPlayers.includes(playerName)) {
                state.savedPlayers.push(playerName)
                state.savedPlayers.sort()
                state.duplicateNameEntered = false
            } else {
                state.duplicateNameEntered = true
            }
        },
        removeSavedPlayer: (state, action: PayloadAction<string>) => {
            state.savedPlayers = state.savedPlayers.filter((player) => {
                return player !== action.payload
            })
        },
        addPlayer: (state, action:PayloadAction<string>) => {
            if (!state.players.includes(action.payload)) {
                state.players.push(action.payload)
                state.players.sort()
                state.duplicateNameEntered = false
            } else {
                state.duplicateNameEntered = true
            }
        },
        removePlayer: (state, action:PayloadAction<string>) => {
            state.players = state.players.filter((player) => {
                return player !== action.payload
            })
        },
        removePlayers: (state, action: PayloadAction<string[]>) => {
            action.payload.forEach((player) => {
                state.savedPlayers = state.savedPlayers.filter((thisPlayer) => {
                    return  thisPlayer !== player
                })
                state.players = state.players.filter((thisPlayer) => {
                    return  thisPlayer !== player
                })
            })
        },
        setAmountOfCardsForRole: (state,
                                  action:PayloadAction<{role: Role, amount: number}>) => {
            state.roles[action.payload.role] = action.payload.amount
        },
        setDiefRole: (state, action: PayloadAction<{ role: Role, index: number }>) => {
            state.diefRoles[action.payload.index] = action.payload.role
        },
        startGame: state => {
            state.players = []
            state.roles = Array(amountOfRoles).fill(0)
        }
    }
})

export const {load, addSavedPlayer,
    removeSavedPlayer, save,
    removePlayer, addPlayer,
    setAmountOfCardsForRole, removePlayers,
    setDiefRole, startGame} = preGameSlice.actions

export default preGameSlice.reducer
