import React from "react";
import {NightPhase} from "./NightPhase";
import styles from "../styles.module.css"
import {AppEndTag} from "../common/AppEndTag";
import {GameHeader} from "../common/GameHeader";


export function Night() {

    return (<>
        <GameHeader/>
        <div className={`${styles.AppBody} ${styles.Nacht}`}>
            <NightPhase />
            <AppEndTag/>
        </div>
    </>)
}