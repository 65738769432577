import React, {JSX, useEffect, useMemo} from 'react';
import './App.css';
import {PreGame} from "./design/preGame/PreGame";
import {MainMenu} from "./design/out-of-game/MainMenu";
import {Game} from "./design/Game";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {selectLocation} from "./features/weerwolven/slices/ControlSelectors";
import styles from "./design/styles.module.css"
import {KorteUitleg} from "./design/out-of-game/KorteUitleg";
import {AppFooter} from "./design/common/AppFooter";
import {isGameSaveToLoadStorageId, save} from "./features/weerwolven/slices/GameSlice";

function App() {
    const stage = useAppSelector(selectLocation)
    const dispatch = useAppDispatch()

    useEffect(() => {
        let isGameSafeStorageValue = localStorage.getItem(isGameSaveToLoadStorageId)
        if (stage === "game") isGameSafeStorageValue = "true"
        window.addEventListener("unload", () => {
            dispatch(save(isGameSafeStorageValue))
        })
    })

    return (
        <div className={styles.App}>
            {getAppTopLevelInterface(stage)}
            <AppFooter />
        </div>
    );
}

function getAppTopLevelInterface(stage: 'main'|'preGame'|'game'|"explanation"): JSX.Element {
    switch (stage) {
        case "preGame":
            return <PreGame />
        case "main":
            return <MainMenu />
        case "game":
            return <Game />
        case "explanation":
            return <KorteUitleg />
    }
}

export default App;
