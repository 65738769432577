import React, {useMemo, useState} from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectNightData,
    selectPersistentNightData, selectPlayers,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {solveWereWolfKill} from "../../../features/weerwolven/utils/night-solver/Utils";
import {DoesHeksHealScreenPart} from "./DoesHeksHealScreenPart";

export function FirstNightHeksPhase() {
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const playersWithUnknownRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.heks.currentHeks]))
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const weerwolvenResult = useMemo(() => solveWereWolfKill({
        cupido: persistentNightData.cupido,
        doesHeksHeal: false,
        cupidoBedPartner: nightData.cupido.currentBedPartner,
        doesKlauwWolfKlauw: nightData.klauwWolf.doesKlauwWolfInfect,
        genezerTarget: nightData.genezer.currentGenezerTarget,
        isStamoudsteProtected: persistentNightData.stamoudsteProtected,
        stamoudste: nightData.currentStamOudste,
        werewolvesTarget: nightData.weerwolven.currentWeerwolvenTarget
    }), [])
    const [doesHeksKill, setDoesHeksKill] = useState(false)
    useMemo(() => {
        if (nightData.heks.heksKillTarget !== "") {
            setDoesHeksKill(true)
        }
    }, [nightData.heks.heksKillTarget])
    const players = useAppSelector(selectPlayers).getAlivePlayers()



    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Heks</div>

        <TextBalloon text={<>De Heks mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Heks?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithUnknownRole.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.heks.currentHeks}
                                         onClick={() => {
                                             nightData.heks.currentHeks = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Heks, deze persoon is vannacht gedood. Wil je je levensdrankje gebruiken?</>}/>

        <DoesHeksHealScreenPart weerwolvenResult={weerwolvenResult}/>

        <TextBalloon text={<>Heks, wil je deze nacht je doods drankje gebruiken? En zo ja, op wie?</>}/>

        {persistentNightData.heksCanUseDeathPotion ? <>
                <p className={`${styles.Vraag}`}>Gebruikt de Heks het doods drankje?<br/>
                    <button className={`${styles.JaNee} ${doesHeksKill ? styles.Selected : ""}`}
                            onClick={() => {
                                setDoesHeksKill(true)
                            }}>Ja</button>
                    <button className={`${styles.JaNee} ${!doesHeksKill ? styles.Selected : ""}`}
                            onClick={() => {
                                setDoesHeksKill(false)
                                nightData.heks.heksKillTarget = ""
                                dispatch(setNightData(nightData))
                            }}>Nee</button>
                </p>

                {doesHeksKill ? <>
                    <p className={`${styles.Vraag}`}>Wie wijst de Heks aan?</p>
                    <div className={`${styles.NamenSelecteren}`}>
                        {players.filter(player => player.name !== nightData.heks.currentHeks)
                            .map(player => {
                                return <SelectablePlayer name={player.name} key={player.name}
                                                         isSelected={player.name === nightData.heks.heksKillTarget}
                                                         onClick={() => {
                                                             nightData.heks.heksKillTarget = player.name
                                                             dispatch(setNightData(nightData))
                                                         }}/>;
                            })}
                    </div>
                </> : ""}
        </> :
            <p>Het doods drankje is al gebruikt.</p>}


        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.heks.currentHeks === "" ||
                (doesHeksKill && nightData.heks.heksKillTarget === "")
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}