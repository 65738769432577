import React, {JSX, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    selectDuplicateNameEntered,
    selectPlayers,
    selectSavedPlayers
} from "../../features/weerwolven/slices/PreGameSelectors";
import {SelectablePlayer} from "../common/SelectablePlayer";
import {addPlayer, addSavedPlayer, removePlayer, save} from "../../features/weerwolven/slices/PreGameSlice";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";
import {playerListEditingPageId, roleSelectionPageId} from "./PreGame";
import styles from "../styles.module.css"


export function PlayerSelection(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const savedPlayers = useAppSelector(selectSavedPlayers)
    const players = useAppSelector(selectPlayers)
    const duplicateNameEntered = useAppSelector(selectDuplicateNameEntered)
    const dispatch = useAppDispatch()
    const [newPlayer, setNewPlayer] = useState("");

    return (
        <>
            <div className={styles.Breadcrumb}>Voorbereiding / Spelers toevoegen</div>
            <br/>
            <div className={styles.AddPlayerName}>
                <p className={styles.Vraag}>Voeg namen van spelers toe</p>
                <input
                    id={"player_name_input"}
                    className={styles.textbox}
                    aria-label="new player"
                    value={newPlayer}
                    onChange={(e) => {
                        setNewPlayer(e.target.value)
                    }}
                    onKeyDown={event => {
                        if (event.key === "Enter" && newPlayer !== "") {
                            dispatch(addSavedPlayer(newPlayer))
                            dispatch(save())
                            setNewPlayer("")
                        }
                    }}
                />
                <button className={styles.ButtonGeneral} onClick={() => {
                    if (newPlayer === "") return
                    dispatch(addSavedPlayer(newPlayer))
                    dispatch(save())
                    setNewPlayer("")
                }}>Toevoegen</button>
            </div>

            {duplicateNameEntered ? <div className={`${styles.Centered} ${styles.Smal}`}>
                <p className={styles.Uitleg}><i className={`fa-solid fa-triangle-exclamation ${styles.Bigtxt}`}
                                         style={{color: "#900"}}></i><br/>
                    Dubbele namen zijn niet toegestaan.<br/><br/></p>
            </div> : ""}

            <p className={styles.Vraag}>Wie spelen er dit spel mee?</p>
            <p className={styles.Uitleg}>Selecteer of deselecteer een naam door erop te klikken.<br/><br/></p>

            <div className={styles.Centered}>
                <p>Aantal spelers geselecteerd: <span className={styles.Bigtxt}>{
                    players.length
                }</span><span className={styles.Bigtxt}>.</span> </p>
            </div>

            <div className={styles.NamenSelecteren}>
                {savedPlayers.map((player): JSX.Element => {
                    const isSelected = players.includes(player)
                    return (<SelectablePlayer key={player} name={player}
                                                   isSelected={isSelected}
                                                   onClick={() => {
                                                       dispatch(isSelected ? removePlayer(player) : addPlayer(player))
                                                   }} />
                    )
                })}
            </div>

            <div className={styles.Centered}>
                <button onClick={() => {
                    changer(playerListEditingPageId)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
                } className={styles.ButtonGeneral}>Namenlijst bewerken</button>
            </div>

            <div className={styles.TerugVerder}>
                <button onClick={() => {
                    dispatch(setLocation('main'))
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }} className={styles.ButtonTerug}>Terug</button>
                <button onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    changer(roleSelectionPageId)
                }} className={styles.ButtonVerder}>Verder</button>
            </div>
        </>
    )
}