import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData,
    selectPersistentNightData,
    selectPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {Role} from "../../../features/weerwolven/data/Role";

export function NormalNightCupidoPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const nightData = useAppSelector(selectNightData)
    const dispatch = useAppDispatch()
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const cupido = players.filter((player) => player.role === Role.CUPIDO)[0]

    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Cupido</div>

        <TextBalloon text={<>Cupido mag wakker worden.<br/>Cupido, bij wie wil jij vannacht logeren?</>}/>

        {currentNight > 2 ? <><p className={`${styles.Uitleg}`}>Cupido heeft vorige nacht gelogeerd bij:</p>
            <button className={`${styles.NaamWeergave}`}>{persistentNightData.cupidoLastNightBedPartner}</button>
        </> : ""
}

        <div className={`${styles.Spacer} ${styles.Een}`}></div>

        <p className={`${styles.Vraag}`}>Bij wie gaat Cupido logeren?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter((player) => player.name !== cupido.name &&
                player.name !== persistentNightData.cupidoLastNightBedPartner).map((player) => {

                const isSelected = player.name === nightData.cupido.currentBedPartner
                return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                    nightData.cupido.currentBedPartner = player.name
                    dispatch(setNightData(nightData))
                }}/>
            })}
        </div>

        <TextBalloon text={<>Cupido gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>

            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.cupido.currentBedPartner === "" && players.length > 2
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}