import {NightData} from "../../../data/NightData";
import {PersistentNightData} from "../../../data/PersistentNightData";
import {Role} from "../../../data/Role";
import {solveNormalNight} from "../normal-night/SolveNormalNight";
import {NightResult} from "../SolveNight";

export function solveFirstNight(nightData: NightData,
                                persistentNightData: PersistentNightData): NightResult {
    const normalNightResult = solveNormalNight(nightData, persistentNightData, 1)
    let persistentDataClone = normalNightResult.newPersistentNightData
    persistentDataClone.stamoudste = nightData.currentStamOudste
    persistentDataClone.cupido = persistentNightData.cupido
    const {teamChanges, roleChanges} = getRoleAndTeamChanges(nightData, persistentDataClone.cupido)
    normalNightResult.roleChanges.forEach((role, player) => {
        roleChanges.set(player, role)
    })
    normalNightResult.teamChanges.forEach((team, player) => {
        teamChanges.set(player, team)
    })
    teamChanges.delete("")
    roleChanges.delete("")

    return {
        deaths: normalNightResult.deaths,
        newPersistentNightData: persistentDataClone,
        roleChanges: roleChanges,
        teamChanges: teamChanges,
        weerwolvenResult: normalNightResult.weerwolvenResult
    }
}

function getRoleAndTeamChanges(nightData: NightData, cupido: string): {
    roleChanges: Map<string, Role>
    teamChanges: Map<string, Role>
} {
    let teamChanges = new Map<string, Role>()
    let roleChanges = new Map<string, Role>()


    nightData.weerwolven.currentWeerwolven.forEach(weerwolf => {
        roleChanges.set(weerwolf, Role.WEERWOLF)
        teamChanges.set(weerwolf, Role.WEERWOLF)
    })
    roleChanges.set(nightData.currentStamOudste, Role.STAM_OUDSTE)
    roleChanges.set(nightData.heks.currentHeks, Role.HEKS)
    roleChanges.set(nightData.vos.currentVos, Role.VOS)
    roleChanges.set(cupido, Role.CUPIDO)
    roleChanges.set(nightData.genezer.currentGenezer, Role.GENEZER)
    roleChanges.set(nightData.groteBozeWolf.currentGroteBozeWolf, Role.GROTE_BOZE_WOLF)
    roleChanges.set(nightData.wildeman.currentWildeman, Role.WILDEMAN)
    roleChanges.set(nightData.ziener.currentZiener, Role.ZIENER)
    roleChanges.set(nightData.klauwWolf.currentKlauwWolf, Role.KLAUW_WOLF)
    roleChanges.set(nightData.raadsheer.currentRaadsheer, Role.STOTTERENDE_RAADSHEER)
    roleChanges.set(nightData.titus.currentTitus, Role.TITUS)
    nightData.siblings.currentBroertjes.forEach(broertje => {
        roleChanges.set(broertje, Role.BROERTJE)
    })
    nightData.siblings.currentZusjes.forEach(zusje => {
        roleChanges.set(zusje, Role.ZUSJE)
    })
    roleChanges.set(nightData.raaf.currentRaaf, Role.DE_RAAF)

    return {
        roleChanges: roleChanges,
        teamChanges: teamChanges
    }
}