import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectPersistentNightData,
} from "../../../features/weerwolven/slices/GameSelectors";
import styles from "../../styles.module.css";
import {Role} from "../../../features/weerwolven/data/Role";
import {TextBalloon} from "../../common/TextBalloon";
import {
    moveToNextPhase,
    setPersistentNightData
} from "../../../features/weerwolven/slices/GameSlice";
import {SelectableRole} from "../../common/SelectableRole";


export function DiefPhase() {
    const currentNight = useAppSelector(selectDayNumber)
    const persistentData = useAppSelector(selectPersistentNightData)
    const dispatch = useAppDispatch()
    const diefChosenRole: Role = persistentData.diefDiscardedRole !== Role.UNKNOWN ?
        persistentData.diefDiscardedRole === persistentData.diefRoles[0] ?
            persistentData.diefRoles[1] : persistentData.diefRoles[0] : Role.UNKNOWN

    const diefRoles = persistentData.diefRoles

    return (<>
        <div className={styles.Breadcrumb}>{currentNight + 1}e Nacht / Dief</div>

        <TextBalloon text={<>De Dief mag wakker worden.</>}/>

        <TextBalloon text={<>Dief, welke rol wil jij spelen in dit spel?</>}/>
        <p className={styles.Vraag}>Welke rol kiest de Dief?</p>


        <div className={`${styles.RollenKiezen} ${styles.Centered}`}>
            <SelectableRole role={diefRoles[0]} isSelected={
                diefRoles[0] === diefChosenRole
            } onClick={() => {
                persistentData.diefDiscardedRole = diefRoles[1]
                dispatch(setPersistentNightData(persistentData))
            }}/>


            <SelectableRole role={diefRoles[1]} isSelected={
                diefRoles[1] === diefChosenRole
            } onClick={() => {
                persistentData.diefDiscardedRole = diefRoles[0]
                dispatch(setPersistentNightData(persistentData))
            }}/>
        </div>

        <div className={`${styles.Spacer} ${styles.Twee}`}>.</div>
        {persistentData.diefDiscardedRole !== Role.UNKNOWN ?
            <TextBalloon text={<>De Dief gaat weer slapen.</>}/> : ""}



        <div className={styles.TerugVerder}>
            <button className={styles.ButtonVerder} disabled={
                persistentData.diefDiscardedRole === Role.UNKNOWN
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>

    </>)
}