import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber, selectNightData, selectPlayers} from "../../../features/weerwolven/slices/GameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";

export function NormalNightGroteBozeWolfPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const nightData = useAppSelector(selectNightData)
    const dispatch = useAppDispatch()



    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Grote Boze Wolf</div>
        <TextBalloon
            text={<>De Grote Boze Wolf mag wakker worden.<br/>Grote Boze Wolf, welke burger wil jij vannacht extra
                opeten?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Grote Boze Wolf aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => player.team !== Role.WEERWOLF && player.team !== Role.WITTE_WEERWOLF)
                .map(player => {
                    return <SelectablePlayer name={player.name} key={player.name}
                                             isSelected={player.name === nightData.groteBozeWolf.groteBozeWolfTarget}
                                             onClick={() => {
                                                 nightData.groteBozeWolf.groteBozeWolfTarget = player.name
                                                 dispatch(setNightData(nightData))
                                             }}/>
                })}
        </div>

        <TextBalloon text={<>De Grote Boze Wolf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.groteBozeWolf.groteBozeWolfTarget === ""
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}