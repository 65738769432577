import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {startNight} from "../../../features/weerwolven/slices/GameSlice";
import {AppEndTag} from "../../common/AppEndTag";

export function SchemeringPhase() {
    const currentNight = useAppSelector(selectDayNumber)
    const dispatch = useAppDispatch()


    return (<div className={`${styles.AppBody} ${styles.Schemering}`}>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Dag / Schemering</div>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        <TextBalloon text={<><br/>Het wordt nacht in het dorp en iedereen gaat slapen.<br/><br/></>}/>

        <div className={`${styles.Spacer} ${styles.Drie}`}></div>
        <div className={`${styles.Spacer} ${styles.Drie}`}></div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(startNight())
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}