import React, {useEffect, useMemo} from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber, selectNightData, selectPlayers} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, moveToPreviousPhase} from "../../../features/weerwolven/slices/GameSlice";
import {Role} from "../../../features/weerwolven/data/Role";

export function NormalNightZienerPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const rawWeerwolven = useAppSelector(selectPlayers).getWeerwolven().map(player => player.name)
    const weerwolven = useMemo(() => {
        let localWeerwolven = [...rawWeerwolven]
        if (nightData.klauwWolf.doesKlauwWolfInfect) {
            localWeerwolven.push(nightData.weerwolven.currentWeerwolvenTarget)
        }
        return localWeerwolven.filter((weerwolf, index, array) => {
            return array.indexOf(weerwolf) === index
        })
    }, [])

    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Ziener</div>

        <TextBalloon text={<>De Ziener mag wakker worden.<br/>Ziener, van wie wil jij zien of het een Weerwolf of goed Volk is?</>}/>

        <p>Dit zijn de Weerwolven:</p>
        {weerwolven.map(player => {
            return <button key={player} className={`${styles.NaamWeergave}`}>{player}</button>
        })}

        <Action text={<><p>Geef met 3 vingers in de lucht aan dat het een Weerwolf is, of met 2 vingers dat het goed Volk is.</p></>}/>

        <TextBalloon text={<>De Ziener gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}