import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {SecondNightWitteWeerwolfPhase} from "./SecondNightWitteWeerwolfPhase";
import {NormalNightWitteWeerwolfPhase} from "./NormalNightWitteWeerwolfPhase";


export function WitteWolfPhase() {
    const isSecondNight = useAppSelector(selectDayNumber) === 1


    return isSecondNight ? <SecondNightWitteWeerwolfPhase/> : <NormalNightWitteWeerwolfPhase/>
}