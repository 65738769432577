import React, {JSX, useMemo} from "react";
import {useAppSelector} from "../../app/hooks";
import {
    selectData,
    selectDayNumber,
    selectNightData,
    selectPersistentNightData, selectPlayers, selectRecentlyKilledPlayers,
    selectWeerwolvenResult
} from "../../features/weerwolven/slices/GameSelectors";
import {DisplayPlayer} from "../common/DisplayPlayer";
import {Role} from "../../features/weerwolven/data/Role";
import styles from "../styles.module.css"
import {Player} from "../../features/weerwolven/data/Interfaces";

export function NightExplanation() {
    const currentNight = useAppSelector(selectDayNumber)
    const nightData = useAppSelector(selectNightData)
    const data = useAppSelector(selectData)
    const playerData = useAppSelector(selectPlayers)
    const weerwolvenDeathResult = useAppSelector(selectWeerwolvenResult)
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const recentlyKilledPlayers = useAppSelector(selectRecentlyKilledPlayers).map(player => player.name)
    const wildeman: Player|undefined = useMemo(() => {
        return playerData.getPlayersByRole(Role.WILDEMAN)[0]
    }, [])
    const wildemanIdolKilled = useMemo(() => {
        const wildemanIdol = persistentNightData.wildemanIdol
        return recentlyKilledPlayers.includes(wildemanIdol) && wildeman !== undefined;
    }, [])

    const loverDeathExplanation: JSX.Element = useMemo(() => {
        if (data.currentLovers.length < 2) return <></>
        const loverDied = recentlyKilledPlayers.includes(data.currentLovers[0])
        if (loverDied) {
            return <p className={styles.Uitleg}>
                <DisplayPlayer name={data.currentLovers[0]} small={true}/> en <DisplayPlayer
                name={data.currentLovers[1]} small={true}/> zijn Geliefden en zijn samen in de nacht dood gegaan.
            </p>
        }
        return <></>
    }, [data, nightData, recentlyKilledPlayers])

    const weerwolvenDeathExplanation: JSX.Element = useMemo(() => {
        let thwartExplanation: JSX.Element = <></>
        switch (weerwolvenDeathResult.reasonOfThwart) {
            case Role.CUPIDO:
                if (currentNight === 1) {
                    break
                }
                thwartExplanation = <>, maar dat was Cupido en die was logeren bij
                    <DisplayPlayer name={nightData.cupido.currentBedPartner} small={true}/></>
                break
            case Role.KLAUW_WOLF:
                thwartExplanation = <>, maar de Klauw wolf heeft hem/haar een Weerwolf gemaakt</>
                break
            case Role.STAM_OUDSTE:
                thwartExplanation = <>, maar dat was de Stamoudste en die gaat de eerste
                    keer dat de Weerwolven hem/haar bijten niet dood</>
                break
            case Role.GENEZER:
                thwartExplanation = <>, maar hij/zij was beschermd door de Genezer</>
                break
            case Role.HEKS:
                thwartExplanation = <>, maar de Heks heeft hem/haar genezen</>
                break
        }
        return thwartExplanation
    }, [nightData, weerwolvenDeathResult, currentNight])


    return <>
        <p className={styles.Uitleg}><DisplayPlayer
            name={nightData.weerwolven.currentWeerwolvenTarget}
            small={true}/> is gebeten door de Weerwolven{weerwolvenDeathExplanation}.<br/>
        {weerwolvenDeathResult.isCupidoKilled ? <p className={styles.Uitleg}>
            <DisplayPlayer name={persistentNightData.cupido} small={true}/> (Cupido)
            sliep bij de persoon die vermoord is door de Weerwolven en is dus ook dood.
        </p> : <></>}</p>
        {nightData.heks.heksKillTarget !== "" ? <p className={styles.Uitleg}>
            <DisplayPlayer name={nightData.heks.heksKillTarget} small={true}/> is vermoord door de Heks.
        </p> : <></>}
        {nightData.groteBozeWolf.groteBozeWolfTarget !== "" ? <p className={styles.Uitleg}>
            <DisplayPlayer name={nightData.groteBozeWolf.groteBozeWolfTarget} small={true}/> is vermoord door de Grote Boze wolf.
        </p> : <></>}
        {nightData.witteWolf.witteWolfTarget !== "" ? <p className={styles.Uitleg}>
            <DisplayPlayer name={nightData.witteWolf.witteWolfTarget} small={true}/> is vermoord door de de Witte weerwolf.
        </p> : <></>}
        {wildemanIdolKilled && wildeman !== undefined ? <>
            <p className={styles.Uitleg}><DisplayPlayer name={wildeman.name} small={true}/> is de Wildeman,
            zijn idool is gestorven, dus hij/zij wordt de komende nacht een Weerwolf.</p>
        </> : <></>}
        {loverDeathExplanation}
    </>
}