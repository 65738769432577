import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightRaafPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const playersWithoutRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.raaf.currentRaaf]))
    const dispatch = useAppDispatch()


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Raaf</div>

        <TextBalloon text={<>De Raaf mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Raaf?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.raaf.currentRaaf}
                                         onClick={() => {
                                             nightData.raaf.currentRaaf = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Raaf, bij wie wil jij je verdenking leggen?</>}/>

        <Action text={<><p>Leg de tegel van de Raaf neer bij degene die de Raaf aanwijst.</p></>}/>

        <TextBalloon text={<>De Raaf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.raaf.currentRaaf === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Verder</button>
        </div>
    </div>)
}