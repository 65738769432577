import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectData,
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData, selectPersistentNightData,
    selectPlayers,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {
    moveToPreviousPhase,
    setData,
    setNightData, setPersistentNightData
} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightCupidoPhase() {
    const nightData = useAppSelector(selectNightData)
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const playersWithoutRole = useAppSelector(selectPlayersWithoutAssignedRole([persistentNightData.cupido]))
    const players = useAppSelector(selectPlayers).getAlivePlayers()
    const data = useAppSelector(selectData)
    const dispatch = useAppDispatch()
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)


    return <div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Cupido</div>

        <TextBalloon text={<>Cupido mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Cupido?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map((player) => {
                const isSelected = player.name === persistentNightData.cupido
                return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                    if (!isSelected) {
                        persistentNightData.cupido = player.name
                        data.currentLovers = data.currentLovers.filter(lover => lover !== player.name)
                        dispatch(setPersistentNightData(persistentNightData))
                        dispatch(setData(data))
                    }
                }}/>
            })}
        </div>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        <TextBalloon text={<>Cupido, wie maak jij de 2 Geliefden?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst Cupido aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.map((player) => {
                const isSelected = data.currentLovers.includes(player.name)
                return <SelectablePlayer key={player.name}
                                         name={player.name}
                                         isSelected={isSelected}
                                         onClick={() => {
                    if (isSelected) {
                        data.currentLovers = data.currentLovers
                            .filter((lover) => lover !== player.name)
                        dispatch(setData(data))
                    } else {
                        data.currentLovers.push(player.name)
                        dispatch(setData(data))
                    }
                }}/>
            })}
        </div>

        {data.currentLovers.length !== 2 ? <div className={`${styles.Centered} ${styles.Smal}`}>
            <p className={`${styles.Uitleg}`}>Selecteer 2 namen.</p>
        </div> : ""}

        <div className={`${styles.Spacer} ${styles.Een}`}></div>

        <TextBalloon text={<>Cupido gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={data.currentLovers.length !== 2 ||
                persistentNightData.cupido === ""} onClick={() => {
                    nightData.cupido.haveLoversMet = true
                    dispatch(setNightData(nightData))
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>
}