export interface NightData {
    cupido: {
        currentBedPartner: string
        haveLoversMet: boolean
    }
    currentStamOudste: string
    wildeman: {
        currentWildeman: string
    }
    weerwolven: {
        currentWeerwolven: string[]
        currentWeerwolvenTarget: string
    }
    klauwWolf: {
        currentKlauwWolf: string
        doesKlauwWolfInfect: boolean
    }
    groteBozeWolf: {
        currentGroteBozeWolf: string
        groteBozeWolfTarget: string
    }
    heks: {
        currentHeks: string
        doesHeksHeal: boolean
        heksKillTarget: string
    }
    ziener: {
        currentZiener: string
    }
    vos: {
        currentVos: string
        hasTheVosFoundAWerewolf: boolean
    }
    raadsheer: {
        currentRaadsheer: string
    }
    siblings: {
        currentZusjes: string[]
        currentBroertjes: string[]
    }
    raaf: {
        currentRaaf: string
    }
    titus: {
        currentTitus: string
    }
    genezer: {
        currentGenezer: string
        currentGenezerTarget: string
    },
    witteWolf: {
        currentWitteWolf: string
        witteWolfTarget: string
        witteWolfDiedEarly: boolean
    }
}

export const initialNightData: NightData = {
    cupido: {
        currentBedPartner: "",
        haveLoversMet: false
    },
    currentStamOudste: "",
    wildeman: {
        currentWildeman: "",
    },
    heks: {
        currentHeks: "",
        doesHeksHeal: false,
        heksKillTarget: ""
    },
    raaf: {
        currentRaaf: ""
    },
    groteBozeWolf: {
        currentGroteBozeWolf: "",
        groteBozeWolfTarget: ""
    },
    klauwWolf: {
        currentKlauwWolf: "",
        doesKlauwWolfInfect: false
    },
    raadsheer: {
        currentRaadsheer: ""
    },
    siblings: {
        currentZusjes: [],
        currentBroertjes: []
    },
    titus: {
        currentTitus: ""
    },
    vos: {
        currentVos: "",
        hasTheVosFoundAWerewolf: true
    },
    weerwolven: {
        currentWeerwolven: [],
        currentWeerwolvenTarget: ""
    },
    ziener: {
        currentZiener: ""
    },
    genezer: {
        currentGenezer: "",
        currentGenezerTarget: ""
    },
    witteWolf: {
        currentWitteWolf: "",
        witteWolfTarget: "",
        witteWolfDiedEarly: false
    }
}