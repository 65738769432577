
export enum Role {
    UNKNOWN,
    WEERWOLF,
    GROTE_BOZE_WOLF,
    KLAUW_WOLF,
    DIEF,
    DIENSTMEISJE,
    TONEELSPELER,
    WILDEMAN,
    WOLFS_HOND,
    WITTE_WEERWOLF,
    FLUITSPELER,
    DE_RAAF,
    BURGER,
    BURGERLIJKE_BURGER,
    ZIENER,
    CUPIDO,
    HEKS,
    JAGER,
    SPIEKENDE_MEISJE,
    GENEZER,
    STAM_OUDSTE,
    ZONDEBOK,
    DORPSGEK,
    ZUSJE,
    BROERTJE,
    VOS,
    TITUS,
    STOTTERENDE_RAADSHEER,
    RIDDER_MET_HET_ROESTIGE_ZWAARD
}


// a list of what roles have what amount of cards(so 4 at index 1 because there are 4 werewolf cards)
let roleAmounts: number[] = Array(29).fill(1)
roleAmounts[Role.WEERWOLF] = 4
roleAmounts[Role.BURGER] = 9
roleAmounts[Role.ZUSJE] = 2
roleAmounts[Role.BROERTJE] = 3
roleAmounts[Role.UNKNOWN] = 0
export default roleAmounts

export const amountOfRoles = 29

export function isRoleCurrentlyAvailable(role: Role) {
    switch (role) {
        case Role.UNKNOWN:
        case Role.DORPSGEK:
        case Role.TONEELSPELER:
        case Role.FLUITSPELER:
        case Role.DIENSTMEISJE:
            return false
        default:
            return true
    }
}

export const displayNames: string[] = Array(amountOfRoles).fill("")
displayNames[Role.VOS] = "de vos"
displayNames[Role.BROERTJE] = "broertje"
displayNames[Role.ZUSJE] = "zusje"
displayNames[Role.BURGER] = "burger"
displayNames[Role.WOLFS_HOND] = "wolfshond"
displayNames[Role.WILDEMAN] = "wildeman"
displayNames[Role.WITTE_WEERWOLF] = "witte weerwolf"
displayNames[Role.KLAUW_WOLF] = "klauw wolf"
displayNames[Role.GROTE_BOZE_WOLF] = "grote boze wolf"
displayNames[Role.DIEF] = "dief"
displayNames[Role.RIDDER_MET_HET_ROESTIGE_ZWAARD] = "de ridder met het roestige zwaard"
displayNames[Role.STOTTERENDE_RAADSHEER] = "stotterende raadsheer"
displayNames[Role.TITUS] = "titus"
displayNames[Role.ZONDEBOK] = "zondebok"
displayNames[Role.STAM_OUDSTE] = "stamoudste"
displayNames[Role.GENEZER] = "genezer"
displayNames[Role.SPIEKENDE_MEISJE] = "het spiekende meisje"
displayNames[Role.JAGER] = "de jager"
displayNames[Role.HEKS] = "de heks"
displayNames[Role.CUPIDO] = "cupido"
displayNames[Role.ZIENER] = "de ziener"
displayNames[Role.BURGERLIJKE_BURGER] = "burgerlijke burger"
displayNames[Role.WEERWOLF] = "weerwolf"
displayNames[Role.FLUITSPELER] = "fluitspeler"
displayNames[Role.TONEELSPELER] = "de toneelspeler"
displayNames[Role.DORPSGEK] = "de dorpsgek"
displayNames[Role.DIENSTMEISJE] = "dienstmeisje"
displayNames[Role.DE_RAAF] = "de raaf"
displayNames[Role.UNKNOWN] = "wtf happened"