import React, {JSX} from "react";
import styles from "../styles.module.css"

export function Action(props: {text: JSX.Element}) {
    return (<div className={styles.Actie}>
        <div className={styles.first}><hr/></div>
        <div className={styles.ActieDing}>▼</div>
        {props.text}
        <div className={styles.second}><hr/></div>
    </div>)
}