import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightHeksPhase} from "./FirstNightHeksPhase";
import {NormalNightHeksPhase} from "./NormalNightHeksPhase";


export function HeksPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0


    return isFirstNight ? <FirstNightHeksPhase/> : <NormalNightHeksPhase/>
}