import React from "react";
import styles from "../styles.module.css";
import cirkel from "../logos/roles/cirkel40.png";
import {useAppDispatch} from "../../app/hooks";
import {setOpenedHeaderTab} from "../../features/weerwolven/slices/GameSlice";

export function GameHeader() {
    const dispatch = useAppDispatch()


    return (<>
        <header className={styles.AppHeader}>
            <div className={styles.HeaderLogo}> <img src={cirkel} alt={"Weerwolven"}/></div>
            <div className={`${styles.Menu} ${styles.Spel}`}>
                <button onClick={() => {
                    dispatch(setOpenedHeaderTab("bliksem"))
                }}><i className="fa-solid fa-bolt-lightning" style={{color: "#c90"}}></i> Bliksem</button>
                <button onClick={() => {
                    dispatch(setOpenedHeaderTab("pauze"))
                }}><i className="fa-regular fa-circle-pause" style={{color: "#c90"}}></i> Pauze</button>
                <button onClick={() => {
                    dispatch(setOpenedHeaderTab("stop"))
                }}><i className="fa-regular fa-circle-stop" style={{color: "#c90"}}></i> Stop</button>
            </div>
        </header>

    </>)
}