import React from "react";
import styles from "../styles.module.css"
import {SelectablePlayer} from "../common/SelectablePlayer";
import {Action} from "../common/Action";
import {TextBalloon} from "../common/TextBalloon";
import {useAppDispatch} from "../../app/hooks";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";
import {AppEndTag} from "../common/AppEndTag";
import cirkel from "../logos/roles/cirkel40.png";

export function KorteUitleg() {
    const dispatch = useAppDispatch()


    return (<>
        <header className={styles.AppHeader}>
            <div className={styles.HeaderLogo}> <img src={cirkel} alt={"Weerwolven"}/> </div>
        </header>
        <div className={styles.AppBody}>
        <div className={styles.Breadcrumb}>Korte uitleg</div>
        <div className={styles.Centered}>
            <button className={styles.ButtonLinkLike} onClick={() => {
                dispatch(setLocation("main"))
            }}>← Terug naar Startscherm</button>
        </div>
        <p className={styles.Vraag}>Wat doet deze app?</p>
        <p className={styles.Uitleg}>Deze app helpt je in je rol als spelleider. Jij geeft aan hoeveel en welke spelers er
            meespelen en welke rollen je in het spel wilt hebben. De rest wijst zich vanzelf. De app vertelt je in
            iedere fase wat te doen en wat te zeggen. </p>
        <br/>
        <p className={styles.Vraag}>Voor wie is deze app?</p>
        <p className={styles.Uitleg}>We maken de app uiteindelijk voor spelleiders met weinig ervaring. Echter is de app nu nog
            in testfase, waardoor nog niet alle functionaliteit of uitleg aanwezig is. Op dit moment is de app dus met
            name geschikt voor mensen die al enige spelleider ervaring hebben.</p>
        <br/>
        <p className={styles.Vraag}>Voordat je met het 1e spel begint...</p>
        <p className={styles.Uitleg}>Al ruim voor je met het eerste spel begint kun je namen van spelers toevoegen aan de app.
            Klik in het Startscherm op 'Begin nieuw spel' en voeg alvast namen toe. Alle namen worden bewaard voor later
            gebruik.</p>
        <br/>
        <p className={styles.Vraag}>Tekstballon</p>
        <TextBalloon text={<>Dit is een tekstballon, waar de tekst in staat die je als spelleider hardop uit
            kunt spreken.</>}/>
        <br/>
        <p className={styles.Vraag}>Actie</p>
        <Action text={<p>Tekst tussen deze blauwe lijnen geven een actie aan die je als spelleider te nemen hebt.</p>}/>

        <p className={styles.Vraag}>Namen selecteren</p>
        <p className={styles.Uitleg}>Namen selecteren of deselecteren doe je door erop te klikken. Grijs is niet geselecteerd,
            blauw juist wel.</p>
        <div className={styles.NamenSelecteren}>
            <SelectablePlayer name={"niet geselecteerd"} isSelected={false} onClick={() => {}}/>
            <SelectablePlayer name={"wel geselecteerd"} isSelected={true} onClick={() => {}}/>
        </div>
        <p className={styles.Vraag}>Bliksem</p>
        <p className={styles.Uitleg}>Tijdens het spel vind je rechtsbovenin een knop Bliksem. Deze kun je gebruiken om een
            speler uit het spel te verwijderen. Je gebruikt de Bliksem ook als de Ridder met het Roestige Zwaard een
            weerwolf dood. En als De Jager een speler doodschiet.</p>
        <p className={styles.Vraag}>Wat als je een fout maakt?</p>
        <p className={styles.Uitleg}>Als je merkt dat je een fout hebt gemaakt kun je meestal terug naar het vorige scherm.
            Onderaan de pagina zit een Terug-knop. Er zijn een paar punten waarna je niet meer terug kunt. Je krijgt dan
            altijd vooraf een waarschuwing.</p>
        <p className={styles.Vraag}>Vragen of feedback?</p>
        <p className={styles.Uitleg}>Heb je vragen over deze app? Heb je een tip of advies? Mis je een functie? Stuur ons
            een <a href="mailto:app@weerwolvenspelleider.nl">email</a>, dan komen we erop terug.</p>
        <p className={styles.Vraag}>Veel plezier!</p>


        <div className={`${styles.Spacer} ${styles.Twee}`}></div>
        <AppEndTag/>
    </div></>)
}