import React from "react";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";


export function TitusPhase() {
    const nightData = useAppSelector(selectNightData)
    const playersWithoutRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.titus.currentTitus]))
    const dispatch = useAppDispatch()

    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Titus en zijn dansende beer</div>

        <TextBalloon text={<>Titus mag even een hand opsteken.</>}/>

        <p className={`${styles.Vraag}`}>Wie is Titus?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.titus.currentTitus}
                                         onClick={() => {
                                             nightData.titus.currentTitus = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Dankjewel Titus, ik heb je gezien.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.titus.currentTitus === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Verder</button>
        </div>
    </div>)
}