import React from "react";
import styles from "../../styles.module.css"
import sunrise from "../sunrise.png"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectAmountOfCardsForRole,
    selectData,
    selectDayNumber, selectPlayers,
    selectRecentlyKilledPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {setData, setDayPhase} from "../../../features/weerwolven/slices/GameSlice";
import {ochtendPageId} from "../DayPhase";
import {AppEndTag} from "../../common/AppEndTag";
import {Role} from "../../../features/weerwolven/data/Role";
import {NightExplanation} from "../../night/NightExplanation";

export function OchtendglorenPhase() {
    const currentNight = useAppSelector(selectDayNumber)
    const recentlyKilledPlayers = useAppSelector(selectRecentlyKilledPlayers)
    const data = useAppSelector(selectData)
    const playerData = useAppSelector(selectPlayers)
    const dispatch = useAppDispatch()
    const amountOfTitus = useAppSelector(selectAmountOfCardsForRole(Role.TITUS))


    return (<div className={`${styles.AppBody} ${styles.Ochtendgloren}`}>


        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Ochtendgloren</div>

        <div className={`${styles.Spacer} ${styles.Een}`}></div>

        <div className={`${styles.Centered} ${styles.Smal}`}>
            <div className={`${styles.Icon}`}><img src={sunrise} alt="sunrise"/></div>
            <p className={`${styles.Vraag}`}>De zon komt bijna op.</p>
        </div>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        <div>
            <p className={`${styles.Uitleg}`}>Deze personen zullen bij het begin van de dag gestorven zijn:</p>
            {recentlyKilledPlayers.map(player => {
                return <button key={player.name} className={`${styles.NaamWeergave}`}>{player.name}</button>
            })}
        </div>
        <div className={`${styles.Spacer} ${styles.Vier}`}></div>

        <NightExplanation/>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        {amountOfTitus > 0 ? <>
            <div>
                <p className={styles.Vraag}>Zal de Beer van Titus grommen?</p>
                <p className={`${styles.Uitleg}`}>Dit is Titus:</p>
                <button className={`${styles.NaamWeergave}`}>{data.titus}</button>
            </div>
            <div className={`${styles.Spacer} ${styles.Een}`}></div>
            <div>
                <p className={`${styles.Uitleg}`}>Dit zijn de Weerwolven:</p>
                {playerData.getWeerwolven().map(player => {
                    return <button key={player.name} className={`${styles.NaamWeergave}`}>{player.name}</button>;
                })}
            </div>
            <div className={`${styles.Spacer} ${styles.Een}`}></div>
            <div>
                <p className={`${styles.Uitleg}`}>Zit er nu een Weerwolf naast Titus?<br/>
                    <button className={`${styles.JaNee} ${data.bearGrowls ? styles.Selected : ""}`} onClick={() => {
                        data.bearGrowls = true;
                        dispatch(setData(data));
                    }}>Ja
                    </button>
                    <button className={`${styles.JaNee} ${!data.bearGrowls ? styles.Selected : ""}`} onClick={() => {
                        data.bearGrowls = false;
                        dispatch(setData(data));
                    }}>Nee
                    </button>
                </p>
            </div>
        </> : <></>}

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>


        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(setDayPhase(ochtendPageId))
            }}>Verder</button>
        </div>
        <AppEndTag/>
    </div>)
}