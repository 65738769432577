import React from "react";
import styles from "../styles.module.css";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {endNight, moveToPreviousPhase} from "../../features/weerwolven/slices/GameSlice";
import {selectDayNumber} from "../../features/weerwolven/slices/GameSelectors";

export function EndNightWarningScreen() {
    const dispatch = useAppDispatch()
    const currentNight = useAppSelector(selectDayNumber) + 1

    return (<>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Einde van de Nacht</div>

        <div className={`${styles.Spacer} ${styles.Een}`}></div>


        <div className={`${styles.Spacer} ${styles.Vier}`}></div>


        <div className={`${styles.Centered} ${styles.Smal}`}>
            <p><i className={`fa-solid fa-triangle-exclamation ${styles.Bigtxt}`} style={{color: "#900",fontSize:"1.5em"}}></i></p>
            <p className={`${styles.Vraag}`}>Let op!</p>
            <p className={`${styles.Uitleg}`}>Wanneer je nu op Verder klikt kun je niet meer aanpassen wat er in de
                nacht is gebeurd.</p>
        </div>


        <div className={`${styles.Spacer} ${styles.Vier}`}></div>
        <div className={`${styles.Spacer} ${styles.Drie}`}></div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} onClick={() => {
                dispatch(endNight())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </>)
}