import React from "react";
import {diefRolesPageId, roleOverviewPageId} from "./PreGame";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {initGame} from "../../features/weerwolven/slices/GameSlice";
import {selectPreGameState, selectRoles} from "../../features/weerwolven/slices/PreGameSelectors";
import {setLocation} from "../../features/weerwolven/slices/ControlSlice";
import {Role} from "../../features/weerwolven/data/Role";
import styles from "../styles.module.css"
import {TextBalloon} from "../common/TextBalloon";
import {startGame} from "../../features/weerwolven/slices/PreGameSlice";


export function WarningPage(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const dispatch = useAppDispatch()
    const preGameState = useAppSelector(selectPreGameState)
    const roles = useAppSelector(selectRoles)

    return (<>
        <div className={styles.Breadcrumb}>Start het spel</div>
        <p>Als iedereen er klaar voor zit...</p>
        <TextBalloon text={<>Het wordt nacht. En alle spelers gaan slapen.</>}/>

        <div className={`${styles.Centered} ${styles.Smal}`}>
            <p><br/><i className="fa-solid fa-triangle-exclamation" style={{color: "#c90",fontSize:"2em"}}></i><br/><br/>
                Wanneer je op START SPEL klikt kun je niet meer terug om rollen te wisselen of spelers toe te voegen.
            </p>
        </div>

        <div className={styles.Centered}>
            <button className={styles.StartSpel} onClick={() => {
                dispatch(initGame(preGameState))
                dispatch(setLocation("game"))
                window.scrollTo({ top: 0, behavior: 'smooth' });
                dispatch(startGame())
            }}>START SPEL</button>
        </div>

        <div className={styles.TerugVerder}>
            <button onClick={() => {
                if (roles[Role.DIEF] >= 1) {
                    changer(diefRolesPageId)
                } else {
                    changer(roleOverviewPageId)
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }} className={styles.ButtonTerug}>Terug</button>
        </div>
    </>)
}