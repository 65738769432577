import React from "react";
import {PlayerSelection} from "./PlayerSelection";
import {RoleSelection} from "./RoleSelection";
import {PlayerListEditing} from "./PlayerListEditing";
import {RoleOverview} from "./RoleOverview";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectSubLocation} from "../../features/weerwolven/slices/ControlSelectors";
import {setSubLocation} from "../../features/weerwolven/slices/ControlSlice";
import {DiefRoles} from "./DiefRoles";
import styles from "../styles.module.css"
import {WarningPage} from "./WarningPage";
import cirkel from "../logos/roles/cirkel40.png";
import {AppEndTag} from "../common/AppEndTag";

export const playerSelectionPageId = "players"
export const roleSelectionPageId = "roles"
export const roleOverviewPageId = "roleOverview"
export const warningPageId = "warning"
export const playerListEditingPageId = "playerEdit"
export const diefRolesPageId = "diefRoles"

export function PreGame() {
    const location = useAppSelector(selectSubLocation)
    const dispatch = useAppDispatch()

    function changeLocation(place: string) {
        switch (place) {
            case playerSelectionPageId:
                dispatch(setSubLocation(0))
                break
            case roleSelectionPageId:
                dispatch(setSubLocation(1))
                break
            case warningPageId:
                dispatch(setSubLocation(2))
                break
            case playerListEditingPageId:
                dispatch(setSubLocation(3))
                break
            case roleOverviewPageId:
                dispatch(setSubLocation(4))
                break
            case diefRolesPageId:
                dispatch(setSubLocation(5))
                break
        }
    }


    return (<>
        <header className={styles.AppHeader}>
            <div className={styles.HeaderLogo}> <img src={cirkel} alt={"Weerwolven"}/> </div>
        </header>
        <div className={styles.AppBody}>{getPregameUI(location, changeLocation)} <AppEndTag/></div>
    </>)
}

function getPregameUI(location: number, changeLocationFunction: (place: string) => void) {
    switch (location) {
        case 0:
            return <PlayerSelection changerFunction={changeLocationFunction}/>
        case 1:
            return <RoleSelection changerFunction={changeLocationFunction}/>
        case 2:
            return <WarningPage changerFunction={changeLocationFunction} />
        case 3:
            return <PlayerListEditing changerFunction={changeLocationFunction}/>
        case 4:
            return <RoleOverview changerFunction={changeLocationFunction}/>
        case 5:
            return <DiefRoles changerFunction={changeLocationFunction}/>
        default:
            return (<></>)
    }
}