import React, {JSX, useState} from "react";
import {SelectablePlayer} from "../common/SelectablePlayer";
import {removePlayers, save} from "../../features/weerwolven/slices/PreGameSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectSavedPlayers} from "../../features/weerwolven/slices/PreGameSelectors";
import {playerSelectionPageId} from "./PreGame";
import styles from "../styles.module.css"
import {AppEndTag} from "../common/AppEndTag";


export function PlayerListEditing(props: {changerFunction:
        (location: string) => void}) {
    const changer = props.changerFunction
    const [markedPlayers, setMarkedPlayers] = useState([] as string[]);
    const dispatch = useAppDispatch()
    const savedPlayers = useAppSelector(selectSavedPlayers)


    return (
        <>
            <div className={styles.Breadcrumb}>Namenlijst Bewerken</div>
            <p className={styles.Vraag}>Namen uit de lijst verwijderen</p>
            <p className={styles.Uitleg}>Selecteer of deselecteer een naam door erop te klikken.<br/></p>
            <div className={styles.NamenSelecteren + " " + styles.Verwijderen}>
                {savedPlayers.map((player): JSX.Element => {
                    const isSelected = markedPlayers.includes(player)
                    return (<SelectablePlayer key={player} name={player} isSelected={isSelected} onClick={() => {
                        if (isSelected) {
                            setMarkedPlayers(markedPlayers.filter((thisPlayer) => {
                                return thisPlayer !== player
                            }))
                        } else {
                            setMarkedPlayers([...markedPlayers, player])
                        }
                    }} />)
                })}
            </div>
            <div className={styles.Centered}>
                <button onClick={() => {
                    dispatch(removePlayers(markedPlayers))
                    dispatch(save())
                }} className={styles.ButtonGeneral}>Alle geselecteerde namen verwijderen</button>
            </div>
            <div className={styles.TerugVerder}>
                <button onClick={() => {
                    changer(playerSelectionPageId)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }} className={styles.ButtonTerug}>terug</button>
            </div>
            <AppEndTag />
        </>
    )
}