import React, {useEffect, useMemo} from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {Action} from "../../common/Action";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {SelectablePlayer} from "../../common/SelectablePlayer";

export function FirstNightZienerPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const playersWithoutRole = useAppSelector(
        selectPlayersWithoutAssignedRole([nightData.ziener.currentZiener]))
    const dispatch = useAppDispatch()
    const weerwolven = useMemo(() => {
        let localWeerwolven = [...nightData.weerwolven.currentWeerwolven]
        if (nightData.klauwWolf.doesKlauwWolfInfect) {
            localWeerwolven.push(nightData.weerwolven.currentWeerwolvenTarget)
        }
        return localWeerwolven.filter((weerwolf, index, array) => {
            return array.indexOf(weerwolf) === index
        })
    }, [])


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Ziener</div>

        <TextBalloon text={<>De Ziener mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Ziener?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                return <SelectablePlayer name={player.name}
                                         key={player.name}
                                         isSelected={player.name === nightData.ziener.currentZiener}
                                         onClick={() => {
                                             nightData.ziener.currentZiener = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Ziener, van wie wil jij zien of het een Weerwolf of goed Volk is?</>}/>

        <p>Dit zijn de Weerwolven:</p>
        {weerwolven.map(weerwolf =>
            <button key={weerwolf} className={`${styles.NaamWeergave}`}>{weerwolf}</button>)}

        <Action
            text={<><p>Geef met 3 vingers in de lucht aan dat het een Weerwolf is, of met 2 vingers dat het goed Volk
                is.</p></>}/>

        <TextBalloon text={<>De Ziener gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`}
                    disabled={nightData.ziener.currentZiener === ""}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}>Verder</button>
        </div>
    </div>)
}