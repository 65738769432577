import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {selectDayNumber, selectNightData, selectPlayers} from "../../../features/weerwolven/slices/GameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function NormalNightWitteWeerwolfPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const dispatch = useAppDispatch()
    const nightData = useAppSelector(selectNightData)
    const playerData = useAppSelector(selectPlayers)
    const otherWolves = playerData.getTeam(Role.WEERWOLF)


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Witte Weerwolf</div>

        <TextBalloon text={<>De Witte Weerwolf mag wakker worden.</>}/>

        <TextBalloon text={<>Witte Weerwolf, wie van de andere Weerwolven wil jij vannacht vermoorden?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Witte Weerwolf aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {otherWolves.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === nightData.witteWolf.witteWolfTarget}
                                         onClick={() => {
                                             nightData.witteWolf.witteWolfTarget = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>De Witte Weerwolf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.witteWolf.witteWolfTarget === "" && otherWolves.length > 0
            }
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}