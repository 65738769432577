import React, {useState} from "react";
import styles from "../styles.module.css"
import {killPlayer, setOpenedHeaderTab} from "../../features/weerwolven/slices/GameSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {selectPlayers} from "../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "./SelectablePlayer";
import {AppEndTag} from "./AppEndTag";

export function BliksemScreen() {
    const dispatch = useAppDispatch()
    const [bliksemTarget, setBliksemTarget] = useState("")
    const players = useAppSelector(selectPlayers).getAlivePlayers()


    return (<div className={styles.AppBody}>
        <div className={`${styles.Breadcrumb}`}>Bliksem</div>

        <div>
            <p className={`${styles.Vraag}`}>Wanneer gebruik je de Bliksem?</p>
            <p className={`${styles.Uitleg}`}>Er zijn 2 gevallen waarin de Bliksem gebruikt kan worden:</p>
            <p>1. Een speler moet stoppen.</p>
            <p className={`${styles.Uitleg}`}>Wanneer een speler tijdens het spel wilt of moet stoppen kun je de speler
                en diens rol hier verwijderen uit het spel.</p>
            {/*<p>2. Ridder met het Roestige Zwaard</p>*/}
            {/*<p className={`${styles.Uitleg}`}>Wanneer de Ridder met het Roestige Zwaard vermoord is in de nacht, doodt*/}
            {/*    hij de eerste Weerwolf links van hem. Hiervoor gebruik je de Bliksem.</p>*/}
            <p>2. De Jager</p>
            <p className={`${styles.Uitleg}`}>Wanneer de Jager gedood wordt, wijst hij een speler aan die hij doodschiet
                vlak voor hij zelf sterft. Gebruik de Bliksem om de aangewezen speler uit het spel te halen.</p>
        </div>

        <div className={`${styles.Spacer} ${styles.Een}`}></div>

        <p className={`${styles.Vraag}`}>Welke speler en diens rol wil je verwijderen?</p>

        <div className={`${styles.NamenSelecteren} ${styles.Verwijderen}`}>
            {players.map(player => {
                return <SelectablePlayer name={player.name} key={player.name}
                                         isSelected={player.name === bliksemTarget}
                                         onClick={() => {
                                             setBliksemTarget(player.name)
                                         }}/>
            })}
        </div>

        <div className={`${styles.Centered}`}>
            <button className={`${styles.ButtonGeneral} ${styles.Rood}`} onClick={() => {
                dispatch(killPlayer(bliksemTarget))
            }}>Speler definitief verwijderen</button>
        </div>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(setOpenedHeaderTab("none"))
            }}>Terug naar het spel</button>
        </div>
        <AppEndTag/>
    </div>)
}