import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectNightData, selectPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightGroteBozeWolfPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const dispatch = useAppDispatch()
    const players = useAppSelector(selectPlayers).getAlivePlayers()


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Grote Boze Wolf</div>

        <TextBalloon text={<>De Grote Boze Wolf mag wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie is de Grote Boze Wolf?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {nightData.weerwolven.currentWeerwolven
                .filter(player => player !== nightData.klauwWolf.currentKlauwWolf)
                .map(player => <SelectablePlayer name={player} key={player}
                                                 isSelected={player === nightData.groteBozeWolf.currentGroteBozeWolf}
                                                 onClick={() => {
                                                     nightData.groteBozeWolf.currentGroteBozeWolf = player
                                                     dispatch(setNightData(nightData))
                                                 }}/>)}
        </div>

        <TextBalloon text={<>Grote Boze Wolf, welke burger wil jij vannacht extra opeten?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijst de Grote Boze Wolf aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => !nightData.weerwolven.currentWeerwolven.includes(player.name)
                && player.name !== nightData.weerwolven.currentWeerwolvenTarget)
                .map(player => {
                    return <SelectablePlayer name={player.name} key={player.name}
                                             isSelected={player.name === nightData.groteBozeWolf.groteBozeWolfTarget}
                                             onClick={() => {
                                                 nightData.groteBozeWolf.groteBozeWolfTarget = player.name
                                                 dispatch(setNightData(nightData))
                                             }}/>
                })}
        </div>

        <TextBalloon text={<>De Grote Boze Wolf gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={
                !nightData.weerwolven.currentWeerwolven.includes(nightData.groteBozeWolf.currentGroteBozeWolf) ||
                nightData.groteBozeWolf.groteBozeWolfTarget === "" ||
                nightData.groteBozeWolf.currentGroteBozeWolf === "" ||
                nightData.groteBozeWolf.groteBozeWolfTarget === nightData.weerwolven.currentWeerwolvenTarget
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}