import React from "react";
import {useAppSelector} from "../app/hooks";
import {selectIsDay, selectOpenedHeaderTab, selectWinningTeam} from "../features/weerwolven/slices/GameSelectors";
import {Role} from "../features/weerwolven/data/Role";
import {Day} from "./day/Day";
import {Night} from "./night/Night";
import {WinScreen} from "./WinScreen";
import {StopScreen} from "./common/StopScreen";
import {PauzeScreen} from "./common/PauzeScreen";
import {BliksemScreen} from "./common/BliksemScreen";
import {GameHeader} from "./common/GameHeader";

export function Game() {
    const isDay = useAppSelector(selectIsDay)
    const winningTeam = useAppSelector(selectWinningTeam)
    const openedHeaderTab = useAppSelector(selectOpenedHeaderTab)

    switch (openedHeaderTab) {
        case "stop":
            return <><GameHeader/><StopScreen/></>
        case "pauze":
            return <><GameHeader/><PauzeScreen/></>
        case "bliksem":
            return <><GameHeader/><BliksemScreen/></>
    }

    return winningTeam ===
        Role.UNKNOWN ?
            isDay ? <Day/> : <Night/>
        : <WinScreen/>
}