import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectAmountOfCardsForRole,
    selectIsCurrentPhaseIsFirstNightPhase,
    selectNightData,
    selectPlayers,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {Role} from "../../../features/weerwolven/data/Role";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";

export function FirstNightWeerwolvenPhase() {
    const playerData = useAppSelector(selectPlayers)
    const players = playerData.getAlivePlayers()
    const nightData = useAppSelector(selectNightData)
    const initialRoles = playerData.initialRoles
    const amountOfNormalWerewolves = useAppSelector(selectAmountOfCardsForRole(Role.WEERWOLF))
    const amountOfBigWolves = useAppSelector(selectAmountOfCardsForRole(Role.GROTE_BOZE_WOLF))
    const amountOfWhiteWolves = useAppSelector(selectAmountOfCardsForRole(Role.WITTE_WEERWOLF))
    const amountOfKlauwWolven = useAppSelector(selectAmountOfCardsForRole(Role.KLAUW_WOLF))
    const amountOfWerewolves = amountOfNormalWerewolves + amountOfBigWolves +
        amountOfWhiteWolves + amountOfKlauwWolven
    const playersWithoutRole = useAppSelector(selectPlayersWithoutAssignedRole(
        nightData.weerwolven.currentWeerwolven))
    const dispatch = useAppDispatch()
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)
    const wolfhondIsInGame = initialRoles[Role.WOLFS_HOND] > 0

    return (<div>
        <div className={`${styles.Breadcrumb}`}>1e Nacht / Weerwolven</div>

        <TextBalloon text={<>De Weerwolven mogen wakker worden.</>}/>

        <p className={`${styles.Vraag}`}>Wie zijn de {amountOfWerewolves} {
            wolfhondIsInGame ? `of ${amountOfWerewolves + initialRoles[Role.WOLFS_HOND]} ` : ""}weerwolven?</p>
        {wolfhondIsInGame ?
            <p className={`${styles.Uitleg}`}>Het wolfshondje kan wel of niet een weerwolf zijn geworden...</p> : ""}
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithoutRole.map(player => {
                const isSelected = nightData.weerwolven.currentWeerwolven.includes(player.name)
                return <SelectablePlayer name={player.name}
                                         isSelected={isSelected}
                                         key={player.name}
                                         onClick={() => {
                                             if (isSelected) {
                                                 nightData.weerwolven.currentWeerwolven = nightData
                                                     .weerwolven.currentWeerwolven.filter(thisPlayer => {
                                                     return thisPlayer !== player.name
                                                 })
                                                 dispatch(setNightData(nightData))
                                             } else {
                                                 nightData.weerwolven.currentWeerwolven.push(player.name)
                                                 dispatch(setNightData(nightData))
                                             }
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>Weerwolven, wie willen jullie vannacht opeten?</>}/>

        <p className={`${styles.Vraag}`}>Wie wijzen de Weerwolven aan?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter(player => !nightData.weerwolven
                .currentWeerwolven.includes(player.name)).map(player => {
                return <SelectablePlayer name={player.name}
                                         key={player.name}
                                         isSelected={player.name === nightData.weerwolven.currentWeerwolvenTarget}
                                         onClick={() => {
                                             nightData.weerwolven.currentWeerwolvenTarget = player.name
                                             dispatch(setNightData(nightData))
                                         }}/>
            })}
        </div>

        <TextBalloon text={<>De Weerwolven gaan weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`} disabled={nightData.weerwolven.currentWeerwolvenTarget === "" ||
                nightData.weerwolven.currentWeerwolven
                    .includes(nightData.weerwolven.currentWeerwolvenTarget) ||
                (nightData.weerwolven.currentWeerwolven.length !== amountOfWerewolves &&
                    nightData.weerwolven.currentWeerwolven.length !== amountOfWerewolves + initialRoles[Role.WOLFS_HOND])}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}