import {NightData} from "../../../data/NightData";
import {PersistentNightData} from "../../../data/PersistentNightData";
import {Role} from "../../../data/Role";
import {solveWereWolfKill} from "../Utils";
import {NightResult} from "../SolveNight";

export function solveNormalNight(nightData: NightData,
                                 persistentNightData: PersistentNightData, night: number): NightResult {
    let persistentDataClone = structuredClone(persistentNightData) as PersistentNightData
    let deaths: string[] = []
    const teamChanges = new Map<string, Role>()
    const roleChanges = new Map<string, Role>()

    persistentDataClone.cupidoLastNightBedPartner = nightData.cupido.currentBedPartner
    persistentDataClone.foxCanUseAbility =
        nightData.vos.hasTheVosFoundAWerewolf && persistentNightData.foxCanUseAbility
    persistentDataClone.genezerLastProtectedPerson = nightData.genezer.currentGenezerTarget
    const werewolvesResult = solveWereWolfKill({
        cupido: persistentDataClone.cupido,
        doesHeksHeal: nightData.heks.doesHeksHeal,
        werewolvesTarget: nightData.weerwolven.currentWeerwolvenTarget,
        stamoudste: night !== 1 ? persistentDataClone.stamoudste : nightData.currentStamOudste,
        isStamoudsteProtected: persistentDataClone.stamoudsteProtected,
        genezerTarget: nightData.genezer.currentGenezerTarget,
        doesKlauwWolfKlauw: nightData.klauwWolf.doesKlauwWolfInfect,
        cupidoBedPartner: nightData.cupido.currentBedPartner
    })
    if (!werewolvesResult.isWerewolvesTargetKilled) {
        switch (werewolvesResult.reasonOfThwart) {
            case Role.HEKS:
                persistentDataClone.heksCanUseHealingPotion = false
                break
            case Role.KLAUW_WOLF:
                persistentDataClone.canKlauwWolfKlauw = false
                teamChanges.set(nightData.weerwolven.currentWeerwolvenTarget, Role.WEERWOLF)
                break
            case Role.STAM_OUDSTE:
                persistentDataClone.stamoudsteProtected = false
                break
        }
    } else {
        deaths.push(nightData.weerwolven.currentWeerwolvenTarget)
    }
    if (werewolvesResult.isCupidoKilled) {
        deaths.push(persistentDataClone.cupido)
    }
    deaths.push(nightData.heks.heksKillTarget,
        nightData.groteBozeWolf.groteBozeWolfTarget,
        nightData.witteWolf.witteWolfTarget)
    if (nightData.heks.heksKillTarget !== "") {
        persistentDataClone.heksCanUseDeathPotion = false
    }
    if (night === 2) {
        roleChanges.set(nightData.witteWolf.currentWitteWolf, Role.WITTE_WEERWOLF)
        teamChanges.set(nightData.witteWolf.currentWitteWolf, Role.WITTE_WEERWOLF)
    }
    teamChanges.delete("")
    roleChanges.delete("")

    return {
        deaths: deaths.filter(player => player !== ""),
        newPersistentNightData: persistentDataClone,
        roleChanges: roleChanges,
        teamChanges: teamChanges,
        weerwolvenResult: werewolvesResult
    }
}