import React from "react";
import styles from "../../styles.module.css"
import {TextBalloon} from "../../common/TextBalloon";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectDayNumber,
    selectIsCurrentPhaseIsFirstNightPhase, selectNightData,
    selectPersistentNightData,
    selectPlayers
} from "../../../features/weerwolven/slices/GameSelectors";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {SelectablePlayer} from "../../common/SelectablePlayer";

export function NormalNightGenezer() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const isFirstPhase = useAppSelector(selectIsCurrentPhaseIsFirstNightPhase)
    const dispatch = useAppDispatch()
    const persistentNightData = useAppSelector(selectPersistentNightData)
    const nightData = useAppSelector(selectNightData)
    const players = useAppSelector(selectPlayers).getAlivePlayers()

    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / Genezer</div>

        <TextBalloon text={<>De Genezer mag wakker worden.<br/>Genezer, wie wil jij vannacht beschermen?</>}/>

        <p className={`${styles.Uitleg}`}>De Genezer heeft vorige nacht deze persoon beschermd:</p>
        <button className={`${styles.NaamWeergave}`}>{persistentNightData.genezerLastProtectedPerson}</button>

        <div className={`${styles.Spacer} ${styles.Een}`}></div>

        <p className={`${styles.Vraag}`}>Wie wordt er vannacht beschermd door de Genezer?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {players.filter((player) => player.name !== persistentNightData.genezerLastProtectedPerson)
                .map((player) => {
                    const isSelected = nightData.genezer.currentGenezerTarget === player.name
                    return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                        nightData.genezer.currentGenezerTarget = player.name
                        dispatch(setNightData(nightData))
                    }}/>
            })}
        </div>

        <TextBalloon text={<>De Genezer gaat weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>

            <button className={`${styles.ButtonTerug}`} disabled={isFirstPhase} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>

            <button className={`${styles.ButtonVerder}`} disabled={
                nightData.genezer.currentGenezerTarget === ""
            } onClick={() => {
                dispatch(moveToNextPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>

        </div>
    </div>)
}