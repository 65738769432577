import {Game, GameData, HeaderTabs} from "../data/Game";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PreGameState} from "../data/Interfaces";
import {NormalData} from "../data/NormalData";
import {NightData} from "../data/NightData";
import {initialPersistentNightData, PersistentNightData} from "../data/PersistentNightData";
import {Role} from "../data/Role";

export const gameStorageId = "currentGame"
export const isGameSaveToLoadStorageId = "isGameSafe"

export const gameSlice = createSlice({
    name: "game",
    initialState: new Game(),
    reducers: {
        initGame: (state, action: PayloadAction<PreGameState>) => {
            const initialStateClone: Game = new Game()
            initialStateClone.initGame(action.payload)
            initialStateClone.persistentNightData = {...initialPersistentNightData, diefRoles: action.payload.diefRoles}
            initialStateClone.startNight()
            return initialStateClone
        },
        startNight: state => {
            const game = Game.getGameFromData(state.getGameData())
            game.startNight()
            return game
        },
        endNight: state => {
            const game = Game.getGameFromData(state.getGameData())
            game.endNight()
            return game
        },
        killPlayer: (state, action: PayloadAction<string>) => {
            const game = Game.getGameFromData(state.getGameData())
            game.killPlayer(action.payload)
            game.players.checkLovers(state.data.currentLovers)
            game.checkWildemanIdol()
            return game
        },
        setData: (state, action: PayloadAction<NormalData>) => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.data = action.payload
            return gameClone
        },
        setNightData: (state, action: PayloadAction<NightData>) => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.nightData = action.payload
            return gameClone
        },
        setPersistentNightData: (state, action: PayloadAction<PersistentNightData>) => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.persistentNightData = action.payload
            return gameClone
        },
        incrementTime: state => {
            state.incrementPlayTime()
        },
        clearDayPlayTime: state => {
            state.clearDayPlayTime()
        },
        clearPhasePlayTime: state => {
            state.clearPhasePlayTime()
        },
        moveToNextPhase: state => {
            const game = Game.getGameFromData(state.getGameData())
            game.moveToNextPhase()
            const diefDiscardedRole = game.persistentNightData.diefDiscardedRole
            const phase = game.getCurrentPhase()
            const persistentNightData = game.persistentNightData
            if (phase === Role.GROTE_BOZE_WOLF && !persistentNightData.groteBozeWolfCanKill) {
                game.moveToNextPhase()
            }
            if (phase === Role.VOS && !persistentNightData.foxCanUseAbility) {
                game.moveToNextPhase()
            }
            if (game.getAmountOfCardsForRole(phase) <= 0 && phase !== Role.WEERWOLF) {
                game.moveToNextPhase()
            }
            return game
        },
        moveToPreviousPhase: state => {
            const game = Game.getGameFromData(state.getGameData())
            game.moveToPreviousPhase()
            const currentPhase = game.getCurrentPhase()
            if (currentPhase === Role.VOS && !game.persistentNightData.foxCanUseAbility) {
                game.moveToPreviousPhase()
            } else if (currentPhase === Role.GROTE_BOZE_WOLF && !game.persistentNightData.groteBozeWolfCanKill) {
                game.moveToPreviousPhase()
            }
            if (game.getAmountOfCardsForRole(currentPhase) <= 0 && currentPhase !== Role.WEERWOLF) {
                game.moveToPreviousPhase()
            }
            return game
        },
        save: (state, action: PayloadAction<string|null>) => {
            localStorage.setItem(gameStorageId, JSON.stringify(state.getGameData()))
            if (action.payload === null) return state
            localStorage.setItem(isGameSaveToLoadStorageId, action.payload)
            return state
        },
        load: state => {
            if (localStorage.getItem(isGameSaveToLoadStorageId) === "true") {
                const possibleNewState = localStorage.getItem(gameStorageId)
                return possibleNewState === null ? new Game() :
                    Game.getGameFromData(JSON.parse(possibleNewState) as GameData)
            }
            return new Game()
        },
        clearRecentlyKilledPlayers: state => {
            state.players.clearRecentlyKilledPlayers()
        },
        setDayPhase: (state, action: PayloadAction<number>) => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.dayPhase = action.payload
            return gameClone
        },
        checkWinConditions: state => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.setWinningTeam()
            return gameClone
        },
        setOpenedHeaderTab: (state, action: PayloadAction<HeaderTabs>) => {
            const gameClone = Game.getGameFromData(state.getGameData())
            gameClone.openedHeaderTab = action.payload
            return gameClone
        },
        stopGame: state => {
            localStorage.setItem(gameStorageId, "")
            localStorage.setItem(isGameSaveToLoadStorageId, "false")
            return state
        }
    }
})

export const {clearPhasePlayTime, clearDayPlayTime,
    setPersistentNightData,
    setData, incrementTime,
    setNightData, endNight,
    startNight, killPlayer,
    moveToNextPhase, moveToPreviousPhase,
    initGame, save,
    load, setDayPhase,
    clearRecentlyKilledPlayers, checkWinConditions,
    setOpenedHeaderTab, stopGame} = gameSlice.actions

export default gameSlice.reducer
