import React from "react";
import {TextBalloon} from "./common/TextBalloon";
import styles from "./styles.module.css"
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    selectDayPhase,
    selectRecentlyKilledPlayers,
    selectWinningTeam
} from "../features/weerwolven/slices/GameSelectors";
import {Role} from "../features/weerwolven/data/Role";
import cirkel from "./logos/roles/cirkel40.png";
import {AppEndTag} from "./common/AppEndTag";
import {setLocation} from "../features/weerwolven/slices/ControlSlice";
import {isGameSaveToLoadStorageId} from "../features/weerwolven/slices/GameSlice";

export function WinScreen() {
    const winningTeam = useAppSelector(selectWinningTeam)
    const dayPhase = useAppSelector(selectDayPhase)
    const recentlyKilledPlayers = useAppSelector(selectRecentlyKilledPlayers)
    let winner = ""
    let multiplePeopleWin = true
    switch (winningTeam) {
        case Role.CUPIDO:
            winner = "de geliefden"
            break
        case Role.WEERWOLF:
            winner = "de Weerwolven"
            break
        case Role.WITTE_WEERWOLF:
            winner = "de witte Weerwolf"
            multiplePeopleWin = false
            break
        case Role.BURGER:
            winner = "de burgers"
            break
    }
    const dispatch = useAppDispatch()


    return (<>
        <header className={styles.AppHeader}>
            <div className={styles.HeaderLogo}> <img src={cirkel} alt={"Weerwolven"}/> </div>
        </header>
        <div className={styles.AppBody}>
        <div className={`${styles.Breadcrumb}`}>Speleinde</div>
        <TextBalloon text={<><br/>{dayPhase === 0 ? <>Het wordt ochtend in het dorp behalve voor {
            recentlyKilledPlayers.map(player =>
                <button key={player.name} className={styles.NaamWeergave}>{player.name}</button>)}</> : "Het spel is uit"
        } en {winner} {multiplePeopleWin ? "hebben" : "heeft"} gewonnen!<br/><br/></>}/>

        <div className={`${styles.Spacer} ${styles.Twee}`}></div>

        {/*<div className={`${styles.Centered} ${styles.Smal}`}>*/}
        {/*    <button className={`${styles.ButtonGeneral}`} disabled={true}>Verloop van dit spel terug lezen.</button>*/}
        {/*    <button className={`${styles.ButtonGeneral}`} disabled={true}>Dit spel een naam geven</button>*/}
        {/*    <button className={`${styles.ButtonGeneral}`} disabled={true}>Vorige spellen</button>*/}
        {/*    <p className={`${styles.Uitleg}`}>*/}
        {/*        Ieder spel wordt opgeslagen en is terug te vinden op datum en tijd in de lijst met vorige spellen.*/}
        {/*    </p>*/}
        {/*</div>*/}

            <div className={styles.TerugVerder}>
                <button className={styles.ButtonTerug} onClick={() => {
                    dispatch(setLocation("main"))
                    localStorage.setItem(isGameSaveToLoadStorageId, "false")
                }}>Terug naar Startscherm</button>
            </div>

        <div className={`${styles.Spacer} ${styles.Drie}`}></div>
            <div className={`${styles.Spacer} ${styles.Drie}`}></div>
            <AppEndTag/>
    </div></>)
}