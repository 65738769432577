import React from "react";
import {useAppSelector} from "../../../app/hooks";
import {selectDayNumber} from "../../../features/weerwolven/slices/GameSelectors";
import {FirstNightGroteBozeWolfPhase} from "./FirstNightGroteBozeWolfPhase";
import {NormalNightGroteBozeWolfPhase} from "./NormalNightGroteBozeWolfPhase";


export function GroteBozeWolfPhase() {
    const isFirstNight = useAppSelector(selectDayNumber) === 0


    return isFirstNight ? <FirstNightGroteBozeWolfPhase/> : <NormalNightGroteBozeWolfPhase/>
}