import React from "react";
import {TextBalloon} from "../../common/TextBalloon";
import styles from "../../styles.module.css"
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    selectAmountOfCardsForRole,
    selectDayNumber,
    selectNightData,
    selectPlayersWithoutAssignedRole
} from "../../../features/weerwolven/slices/GameSelectors";
import {SelectablePlayer} from "../../common/SelectablePlayer";
import {moveToNextPhase, moveToPreviousPhase, setNightData} from "../../../features/weerwolven/slices/GameSlice";
import {Role} from "../../../features/weerwolven/data/Role";


export function BroertjesPhase() {
    const currentNight = useAppSelector(selectDayNumber) + 1
    const nightData = useAppSelector(selectNightData)
    const playersWithUnassignedRoles = useAppSelector(
        selectPlayersWithoutAssignedRole(nightData.siblings.currentBroertjes))
    const dispatch = useAppDispatch()
    const amountOfBroertjes = useAppSelector(selectAmountOfCardsForRole(Role.BROERTJE))


    return (<div>
        <div className={`${styles.Breadcrumb}`}>{currentNight}e Nacht / De Drie Broertjes</div>

        <TextBalloon text={<>De Broertjes mogen wakker worden en elkaar even aankijken.</>}/>

        <p className={`${styles.Vraag}`}>Wie zijn de Broertjes?</p>
        <div className={`${styles.NamenSelecteren}`}>
            {playersWithUnassignedRoles.map(player => {
                const isSelected = nightData.siblings.currentBroertjes.includes(player.name)
                return <SelectablePlayer key={player.name} name={player.name} isSelected={isSelected} onClick={() => {
                    if (!isSelected) {
                        nightData.siblings.currentBroertjes.push(player.name)
                        dispatch(setNightData(nightData))
                    } else {
                        nightData.siblings.currentBroertjes = nightData.siblings.currentBroertjes.filter(thisPlayer => {
                            return thisPlayer !== player.name
                        })
                        dispatch(setNightData(nightData))
                    }
                }}/>
            })}
        </div>

        <TextBalloon text={<>De Broertjes gaan weer slapen.</>}/>

        <div className={`${styles.TerugVerder}`}>
            <button className={`${styles.ButtonTerug}`} onClick={() => {
                dispatch(moveToPreviousPhase())
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Terug</button>
            <button className={`${styles.ButtonVerder}`}
                    disabled={nightData.siblings.currentBroertjes.length !== amountOfBroertjes}
                    onClick={() => {
                        dispatch(moveToNextPhase())
                        window.scrollTo({ top: 0, behavior: 'smooth' });
            }}>Verder</button>
        </div>
    </div>)
}